define('ember-js-getting-started/components/graph-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getUrlTracks = function getUrlTracks(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/utmTracks/urls',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('urlTracks', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  var getTotalLicenses = function getTotalLicenses(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/statistics/licenses',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('licenses', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  var getCfWhatsVigencyCustomersPie = function getCfWhatsVigencyCustomersPie(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/cfWhatsStatistcs/vigencyCustomers/pie',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('cfWhatsVigencyCustomersDataOfPie', response);

        var ctx = document.getElementById("cfWhatsVigencyCustomersPieChart");
        var myChart = new Chart(ctx, {
          type: 'pie',
          data: {
            datasets: [{
              data: response.qts,
              backgroundColor: ['#0676FB', '#f04e23', '#091b32', '#00adf2']
            }],
            labels: response.origins
          }
        });

        self.set('cfWhatsVigencyCustomersPie', myChart);

        var ctx2 = document.getElementById("cfWhatsActvatedUsersPieChart");
        var myChart2 = new Chart(ctx2, {
          type: 'pie',
          data: {
            datasets: [{
              data: [100 - response.percentActiveUsers, response.percentActiveUsers],
              backgroundColor: ['#7442f4', '#1ebea5']
            }],
            labels: ['Usuários inativos', 'Usuários em atividade']
          }
        });

        self.set('percentActiveUsersX', response.percentActiveUsers);

        self.set('cfWhatsActvatedUsersPie', myChart2);

        //alert(self.get('totalOrders'));

        if (response.totalOrders <= self.get('totalOrders')) {
          self.set('totalOrders', response.totalOrders);
        }

        if (response.totalOrders > self.get('totalOrders')) {
          var playBeep = document.getElementById("playBeep");
          playBeep.play();
          self.set('totalOrders', response.totalOrders);
        }
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),

    //filtro
    all: true,
    forgram: false,
    whatsmaker: false,
    connectface: false,
    tagclick: false,
    selectedProd: 'Todos Produtos',
    totalOrders: 0,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();

      var handler;
      var self = this;
      handler = setInterval(function () {
        if (Ember.getOwner(self).lookup('controller:application').currentPath === 'panel.ticket') {
          console.log('clock: ' + new Date());
          //getCfWhatsVigencyCustomersPie(self, token);
        }
      }, 60000); //60000 1 minutos


      getTotalLicenses(this, token);
      getUrlTracks(this, token);
    }),

    teardown: function () {
      clearInterval(this.get('handler'));
    }.on('willDestroyElement'),

    percentActiveUsersX: function () {
      return null;
    }.property('percentActiveUsersX'),

    cfWhatsActvatedUsersPie: function () {
      return null;
    }.property('cfWhatsActvatedUsersPie'),

    cfWhatsVigencyCustomersPie: function () {
      return null;
    }.property('cfWhatsVigencyCustomersPie'),

    cfWhatsVigencyCustomersDataOfPie: function () {
      return null;
    }.property('cfWhatsVigencyCustomersDataOfPie'),

    chart1: function () {
      var ctx = document.getElementById("myChart1");
      var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
          datasets: [{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
            borderColor: ['rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
      return myChart;
    }.property('chart1'),

    chart2: function () {
      var ctx = document.getElementById("myChart2");
      var myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
          datasets: [{
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
            borderColor: ['rgba(255,99,132,1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
            borderWidth: 1
          }]
        },
        options: {
          scales: {
            yAxes: [{
              stacked: true
            }]
          }
        }
      });
      return myChart;
    }.property('chart2'),

    chart3: function () {
      var ctx = document.getElementById("myChart3");
      var myChart = new Chart(ctx, {
        type: 'pie',
        data: {
          datasets: [{
            data: [150, 33, 5],
            backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)']
          }],

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: ['APC', 'HOTMART', 'SGPL']
        }
      });
      return myChart;
    }.property('chart3'),

    actions: {
      playBeep: function playBeep() {
        var playBeep = document.getElementById("playBeep");
        playBeep.play();
      },
      selectAll: function selectAll() {
        this.set('all', true);
        this.set('whatsmaker', false);
        this.set('connectface', false);
        this.set('tagclick', false);
        this.set('forgram', false);
        this.set('selectedProd', 'Todos Produtos');
      },
      selectTagclick: function selectTagclick() {
        this.set('all', false);
        this.set('whatsmaker', false);
        this.set('connectface', false);
        this.set('tagclick', true);
        this.set('forgram', false);
        this.set('selectedProd', 'Tagclick');
      },
      selectWhatsmaker: function selectWhatsmaker() {
        this.set('all', false);
        this.set('whatsmaker', true);
        this.set('connectface', false);
        this.set('tagclick', false);
        this.set('forgram', false);
        this.set('selectedProd', 'Whatsmaker');
        var token = this.get('authenticationService').get();
        getCfWhatsVigencyCustomersPie(this, token);
      },
      selectConnectface: function selectConnectface() {
        this.set('all', false);
        this.set('whatsmaker', false);
        this.set('connectface', true);
        this.set('tagclick', false);
        this.set('forgram', false);
        this.set('selectedProd', 'Connectface');
      },
      selectForgram: function selectForgram() {
        this.set('all', false);
        this.set('whatsmaker', false);
        this.set('connectface', false);
        this.set('tagclick', false);
        this.set('forgram', true);
        this.set('selectedProd', 'Forgram');
        var token = this.get('authenticationService').get();
        getCfWhatsVigencyCustomersPie(this, token);
      },
      openPreviewModal: function openPreviewModal() {
        $('#previewModal').modal('show');
      }
    }

  });
});