define('ember-js-getting-started/helpers/ticket-status-tag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ticketStatusTag = ticketStatusTag;
  function ticketStatusTag(params /*, hash*/) {
    var status = params[0];

    if (status === "RESOLVIDO") {
      return '<span class="badge badge-success">Resolvido</span> ';
    }

    if (status === "PENDENTE") {
      return '<span class="badge badge-warning">Pendente</span> ';
    }

    if (status === "CANCELADO") {
      return '<span class="badge badge-danger">Cancelado</span> ';
    }

    return 'INVALID';
  }

  exports.default = Ember.Helper.helper(ticketStatusTag);
});