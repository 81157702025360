define('ember-js-getting-started/helpers/ticket-product-tag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ticketProductTag = ticketProductTag;
  function ticketProductTag(params /*, hash*/) {
    var prod = params[0];

    return '<span class="badge badge-secondary">' + prod + '</span> ';
  }

  exports.default = Ember.Helper.helper(ticketProductTag);
});