define("ember-js-getting-started/helpers/ticket-situation-tag", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ticketSituationTag = ticketSituationTag;
  function ticketSituationTag(params /*, hash*/) {
    var value = params[0];

    if (value !== null && value !== "") {

      if (value === "Pré Venda") return '<span class="badge badge-secondary">Pré Venda</span>';

      if (value === "Pós Venda") return '<span class="badge badge-secondary">Pós Venda</span>';

      return null;
    }

    return null;
  }

  exports.default = Ember.Helper.helper(ticketSituationTag);
});