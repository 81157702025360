define('ember-js-getting-started/components/user-area-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),

    actions: {
      toFirstAccess: function toFirstAccess() {
        this.get('routing').transitionTo('panel.first-access');
      }
    }
  });
});