define('ember-js-getting-started/components/knowledge-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validateForm = function validateForm(self) {
    if (self.get('viewCart') === null || self.get('viewCart') === undefined || self.get('viewCart') === " " || self.get('viewCart') === "") {
      alert('Selecione ao menos Uma classe para acesso.');
      return false;
    }

    if (self.get('newKnowledgeProduct.name') === null || self.get('newKnowledgeProduct.name') === undefined || self.get('newKnowledgeProduct.name') === " " || self.get('newKnowledgeProduct.name') === "") {
      alert('campo Categoria é obrigatório');
      return false;
    }

    if (self.get('newKnowledgeType.type') === null || self.get('newKnowledgeType.type') === undefined || self.get('newKnowledgeType.type') === " " || self.get('newKnowledgeType.type') === "") {
      alert('campo Tipo é obrigatório');
      return false;
    }

    if (self.get('newKnowledgDescription') === null || self.get('newKnowledgDescription') === undefined || self.get('newKnowledgDescription') === " " || self.get('newKnowledgDescription') === "") {
      alert('campo Descrição é obrigatório');
      return false;
    }

    if (self.get('newKnowledgeUrl') === null || self.get('newKnowledgeUrl') === undefined || self.get('newKnowledgeUrl') === " " || self.get('newKnowledgeUrl') === "") {
      alert('campo URL é obrigatório');
      return false;
    }

    return true;
  };

  var _cleanTags = function _cleanTags(self) {
    var token = self.get('authenticationService').get();
    self.set('search', null);
    self.set('searchType', null);
    self.set('searchTypeId', null);

    self.set('searchProduct', null);
    self.set('searchProductId', null);

    self.set('loading', true);
    getKnowledges(self, token);
  };

  var getPermissions = function getPermissions(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/permissions?' + '&page=1&perPage=20',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('permissions', response);
      },
      failure: function failure(response) {
        console.log(response);
      },
      error: function error(response) {
        console.log(response);
      }
    });
  };

  var getKnowledges = function getKnowledges(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/knowledges/paginate?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&search=' + self.get('search') + '&type=' + self.get('searchTypeId') + '&product=' + self.get('searchProductId'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('knowledges', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  var getTypes = function getTypes(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/knowledges/types',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('types', response);
      },
      failure: function failure(response) {
        console.log(response);
      },
      error: function error(response) {
        console.log(response);
      }
    });
  };

  var getProducts = function getProducts(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/products/all',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('products', response);
      },
      failure: function failure(response) {
        console.log(response);
      },
      error: function error(response) {
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),
    utilService: Ember.inject.service('util-service'),

    searchPerPage: 10,
    searchPage: 1,
    search: null,

    loading: true,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('permission', this.get('authenticationService').get().extras.user.permissions[0].key);
      getKnowledges(this, token);
      getTypes(this, token);
      getProducts(this, token);
      getPermissions(this, token);

      this.set('cartItens', new Array());
    }),

    //##########
    //permissões
    //##########
    master: function () {
      if (this.get('permission') === "MASTER") return true;else return false;
    }.property('master'),

    operatorA: function () {
      if (this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorA'),

    operatorB: function () {
      if (this.get('permission') === "OPERATOR_B" || this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorB'),

    designerA: function () {
      if (this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    designerB: function () {
      if (this.get('permission') === "DESIGNER_B" || this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    programmerA: function () {
      if (this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    programmerB: function () {
      if (this.get('permission') === "PROGRAMMER_B" || this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    actions: {
      nextPage: function nextPage() {
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') < this.get('knowledges.meta.totalPages')) {
          this.set('searchPage', this.get('searchPage') + 1);
        }
        this.set('loading', true);
        getKnowledges(this, token);
      },
      beforePage: function beforePage() {
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') > 1) {
          this.set('searchPage', this.get('searchPage') - 1);
        }
        this.set('loading', true);
        getKnowledges(this, token);
      },
      addNewKnowledgePermission: function addNewKnowledgePermission(pack) {
        var inCart = false;
        this.set('viewCart', null);

        if (this.get('cartItens').length > 0) {
          for (var i = 0; i < this.get('cartItens').length; i++) {
            if (pack !== undefined && this.get('cartItens') !== undefined) {
              if (pack.id === this.get('cartItens')[i].id) {
                inCart = true;
              }
            }
          }
        }

        //add novos itens repetidos
        if (inCart === true) {
          this.set('viewCart', this.get('cartItens'));
        }

        //add o primeiro item
        if (inCart === false) {
          this.get('cartItens').pushObject(pack);
          this.set('viewCart', this.get('cartItens'));
        }
      },
      removeNewKnowledgePermission: function removeNewKnowledgePermission(pack) {
        this.set('viewCart', null);

        if (this.get('cartItens').length > 0) {
          for (var i = 0; i < this.get('cartItens').length; i++) {
            if (pack !== undefined && this.get('cartItens') !== undefined) {
              if (pack.id === this.get('cartItens')[i].id) {
                var toRemove = this.get('cartItens').findBy('id', pack.id);
                if (!toRemove) {
                  return;
                }
                this.get('cartItens').removeObject(toRemove);
              }
            }
          }
        }

        this.set('viewCart', this.get('cartItens'));
      },
      openNewKnowledgeModal: function openNewKnowledgeModal() {
        var token = this.get('authenticationService').get();
        getPermissions(this, token);

        this.set('cartItens', new Array());
        this.set('viewCart', null);
        this.set('newKnowledgeProduct', null);
        this.set('newKnowledgeType', null);
        this.set('newKnowledgDescription', null);
        this.set('newKnowledgeUrl', null);

        $("#newKnowledgeModal").modal('show');
      },
      searchType: function searchType(type) {
        var token = this.get('authenticationService').get();
        this.set('searchTypeId', type.id);
        this.set('searchType', type);
        this.set('loading', true);
        getKnowledges(this, token);
      },
      searchProduct: function searchProduct(product) {
        var token = this.get('authenticationService').get();
        this.set('searchProductId', product.id);
        this.set('searchProduct', product);
        this.set('loading', true);
        getKnowledges(this, token);
      },
      keyUpSearchTicket: function keyUpSearchTicket() {
        var token = this.get('authenticationService').get();
        this.set('loading', true);
        getKnowledges(this, token);
      },
      goTo: function goTo(url) {
        if (url !== 'null' && url !== undefined && url !== "" && url !== " ") {
          var win = window.open(url, '_blank');
          win.focus();
        } else {
          alert("Este conhecimento não possui um repositorio cadastrado.");
        }
      },
      cleanTags: function cleanTags() {
        _cleanTags(this);
      },
      setNewKnowledgeType: function setNewKnowledgeType(type) {
        this.set('newKnowledgeType', type);
      },
      setNewKnowledgeProduct: function setNewKnowledgeProduct(product) {
        this.set('newKnowledgeProduct', product);
      },
      editNewKnowledge: function editNewKnowledge() {
        var token = this.get('authenticationService').get();
        var submit = validateForm(this);
        var self = this;

        if (submit === true) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/knowledges',
            type: 'PUT',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: '{"idKnowledgeType":"' + self.get('newKnowledgeType.id') + '",' + '"id":"' + self.get('showKnow.id') + '",' + '"description":"' + self.get('newKnowledgDescription') + '",' + '"idProduct":"' + self.get('newKnowledgeProduct.id') + '",' + '"permissions":' + JSON.stringify(self.get('viewCart')) + ',' + '"url":"' + self.get('newKnowledgeUrl') + '"}',
            success: function success(response) {
              getKnowledges(self, token);
              $("#editKnowledgeModal").modal('hide');
            },
            failure: function failure(response) {
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      saveNewKnowledge: function saveNewKnowledge() {
        var token = this.get('authenticationService').get();
        var submit = validateForm(this);
        var self = this;

        if (submit === true) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/knowledges',
            type: 'POST',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: '{"idKnowledgeType":"' + self.get('newKnowledgeType.id') + '",' + '"description":"' + self.get('newKnowledgDescription') + '",' + '"idProduct":"' + self.get('newKnowledgeProduct.id') + '",' + '"permissions":' + JSON.stringify(self.get('viewCart')) + ',' + '"url":"' + self.get('newKnowledgeUrl') + '"}',
            success: function success(response) {
              getKnowledges(self, token);
              $("#newKnowledgeModal").modal('hide');
            },
            failure: function failure(response) {
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      delete: function _delete(knowledge) {
        var token = this.get('authenticationService').get();
        var self = this;

        if (!confirm("Tem certeza que deseja excluir este conhecimento?")) {
          return;
        }

        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/knowledges/' + knowledge.id,
          type: 'DELETE',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            if (response.code !== 0) {
              alert('Excluido!');

              $("#showKnowModal").modal('hide');

              getKnowledges(self, token);
            } else {
              alert('Erro: ' + response.message);
            }
          },
          failure: function failure(response) {
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('loading', false);
            console.log(response);
          }
        });
      },
      open: function open(knowledge) {
        this.set('showKnow', knowledge);
        $("#showKnowModal").modal('show');
      },
      edit: function edit(knowledge) {
        this.set('cartItens', new Array());
        this.set('viewCart', null);
        this.set('newKnowledgeProduct', null);
        this.set('newKnowledgeType', null);
        this.set('newKnowledgDescription', null);
        this.set('newKnowledgeUrl', null);

        $("#showKnowModal").modal('hide');
        $("#editKnowledgeModal").modal('show');
      }
    }
  });
});