define('ember-js-getting-started/components/user-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var clearNewUserForm = function clearNewUserForm(self) {
    self.set('email', null);
    self.set('fullName', null);
  };

  var clearEditUserForm = function clearEditUserForm(self) {
    self.set('showUser.email', null);
    self.set('showUser.fullName', null);
  };

  var getUsers = function getUsers(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/users?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&searchText=' + self.get('search'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('users', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  var getPermissions = function getPermissions(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/permissions?' + '&page=1&perPage=20',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('permissions', response);
      },
      failure: function failure(response) {
        console.log(response);
      },
      error: function error(response) {
        console.log(response);
      }
    });
  };

  var validateNewUserForm = function validateNewUserForm(self) {
    if (self.get('email') === null || self.get('email') === undefined || self.get('email') === " " || self.get('email') === "") {
      alert('campo E-mail é obrigatório');
      return false;
    }

    if (self.get('fullName') === null || self.get('fullName') === undefined || self.get('fullName') === " " || self.get('fullName') === "") {
      alert('campo Nome Completo é obrigatório');
      return false;
    }

    return true;
  };

  var validateEditUserForm = function validateEditUserForm(self) {
    if (self.get('showUser.email') === null || self.get('showUser.email') === undefined || self.get('showUser.email') === " " || self.get('showUser.email') === "") {
      alert('campo E-mail é obrigatório');
      return false;
    }

    if (self.get('showUser.fullName') === null || self.get('showUser.fullName') === undefined || self.get('showUser.fullName') === " " || self.get('showUser.fullName') === "") {
      alert('campo Nome Completo é obrigatório');
      return false;
    }

    return true;
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),

    loading: true,

    searchPerPage: 8,
    searchPage: 1,
    search: null,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('permission', this.get('authenticationService').get().extras.user.permissions[0].key);
      getUsers(this, token);
      getPermissions(this, token);
    }),

    users: function () {
      return null;
    }.property('users'),

    selectedPermission: function () {
      return null;
    }.property('selectedPermission'),

    //##########
    //permissões
    //##########
    master: function () {
      if (this.get('permission') === "MASTER") return true;else return false;
    }.property('master'),

    operatorA: function () {
      if (this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorA'),

    operatorB: function () {
      if (this.get('permission') === "OPERATOR_B" || this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorB'),

    designerA: function () {
      if (this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    designerB: function () {
      if (this.get('permission') === "DESIGNER_B" || this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    programmerA: function () {
      if (this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    programmerB: function () {
      if (this.get('permission') === "PROGRAMMER_B" || this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    actions: {
      openNewUserModal: function openNewUserModal() {
        $("#newUserModal").modal('show');
      },
      openEditUserModal: function openEditUserModal(user) {
        $("#editUserModal").modal('show');
      },
      setPermission: function setPermission(key) {
        this.set('selectedPermission', key);
      },
      resetPass: function resetPass(id) {
        var self = this;
        var token = this.get('authenticationService').get();
        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/users/' + id + '/resetPass',
          type: 'PUT',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            self.set('loading', false);
            if (response.code !== 0) {
              alert("senha alterada com sucesso.");
              //LIMPA FORMULARIO
              clearNewUserForm(self);
              //BUSCA Usuarios
              getUsers(self, token);
              //fecha o modal
              $("#newUserModal").modal('hide');
            } else {
              alert('Erro: ' + response.message);
            }
          },
          failure: function failure(response) {
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('loading', false);
            console.log(response);
          }
        });
      },
      disableUser: function disableUser(id) {
        var token = this.get('authenticationService').get();
        var self = this;

        if (confirm("Tem certeza que deseja desativar este usuário?")) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/users/' + id,
            type: 'DELETE',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            success: function success(response) {
              self.set('loading', false);
              if (response.code !== 0) {
                alert("usuário deletado.");
                //BUSCA Usuarios
                getUsers(self, token);
                //fecha o modal
                $("#showUserModal").modal('hide');
              } else {
                alert('Erro: ' + response.message);
              }
            },
            failure: function failure(response) {
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      saveNewUser: function saveNewUser() {
        var token = this.get('authenticationService').get();
        var submit = validateNewUserForm(this);
        var self = this;

        if (submit === true) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/users',
            type: 'POST',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: '{"email":"' + self.get('email') + '",' + '"fullName":"' + self.get('fullName') + '",' + '"password":"' + '123456' + '"}',
            success: function success(response) {
              self.set('loading', false);
              if (response.code !== 0) {
                if (response.code === 173) {
                  alert("email em uso.");
                } else {
                  alert("usuário cadastrado com sucesso.");
                }

                //LIMPA FORMULARIO
                clearNewUserForm(self);
                //BUSCA Usuarios
                getUsers(self, token);
                //fecha o modal
                $("#editUserModal").modal('hide');
              } else {
                alert('Erro: ' + response.message);
              }
            },
            failure: function failure(response) {
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      saveEditUser: function saveEditUser(showUser) {
        var token = this.get('authenticationService').get();
        var submit = validateEditUserForm(this);
        var self = this;

        if (submit === true) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/users/' + showUser.id,
            type: 'PUT',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: '{"email":"' + self.get('showUser.email') + '",' + '"fullName":"' + self.get('showUser.fullName') + '",' + '"permission":"' + self.get('selectedPermission') + '"}',
            success: function success(response) {
              self.set('loading', false);
              if (response.code !== 0) {
                alert("usuário editado com sucesso.");
                //BUSCA Usuarios
                getUsers(self, token);
                //fecha o modal
                $("#editUserModal").modal('hide');
              } else {
                alert('Erro: ' + response.message);
              }
            },
            failure: function failure(response) {
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      keyUpSearchUser: function keyUpSearchUser() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getUsers(this, token);
      },
      openShowUserModal: function openShowUserModal(user) {
        if (this.get('permission') === "MASTER") {
          this.set('showUser', user);
          $("#showUserModal").modal('show');
        }
      },
      nextPage: function nextPage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') < this.get('users.meta.totalPages')) {
          this.set('searchPage', this.get('searchPage') + 1);
        }
        getUsers(this, token);
      },
      beforePage: function beforePage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') > 1) {
          this.set('searchPage', this.get('searchPage') - 1);
        }
        getUsers(this, token);
      }
    }
  });
});