define("ember-js-getting-started/helpers/ticket-situation", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ticketSituation = ticketSituation;
  function ticketSituation(params /*, hash*/) {
    var value = params[0];

    if (value !== null && value !== "") {

      if (value === true) return "Pré Venda";

      if (value === false) return "Pós Venda";

      return null;
    }

    return null;
  }

  exports.default = Ember.Helper.helper(ticketSituation);
});