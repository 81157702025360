define("ember-js-getting-started/helpers/cielo-subsc-machine-quantity", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cieloSubscMachineQuantity = cieloSubscMachineQuantity;
  function cieloSubscMachineQuantity(params /*, hash*/) {
    var value = params[0];

    if (value !== null && value !== "") {
      return value - 1;
    }

    return;
  }

  exports.default = Ember.Helper.helper(cieloSubscMachineQuantity);
});