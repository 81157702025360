define('ember-js-getting-started/components/panel-navigation-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    authenticationService: Ember.inject.service('auth-token-service'),

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('user', token.extras.user);
    }),

    user: function () {
      return null;
    }.property('user'),

    actions: {
      logoff: function logoff() {
        this.get('authenticationService').logoff(this);
        this.get('routing').transitionTo('login');
      },
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      },
      toUserArea: function toUserArea() {
        this.get('routing').transitionTo('panel.user-area');
      }
    }
  });
});