define('ember-js-getting-started/components/logs-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getLogs = function getLogs(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/logs/paginate?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&search=' + self.get('search') + '&status=' + self.get('searchStatus'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('logs', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),
    utilService: Ember.inject.service('util-service'),

    loading: true,

    searchPerPage: 10,
    searchPage: 1,
    search: null,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('permission', this.get('authenticationService').get().extras.user.permissions[0].key);
      getLogs(this, token);
    }),

    permission: function () {
      return null;
    }.property('permission'),

    //##########
    //permissões
    //##########
    master: function () {
      if (this.get('permission') === "MASTER") return true;else return false;
    }.property('master'),

    operatorA: function () {
      if (this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorA'),

    operatorB: function () {
      if (this.get('permission') === "OPERATOR_B" || this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorB'),

    designerA: function () {
      if (this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    designerB: function () {
      if (this.get('permission') === "DESIGNER_B" || this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    programmerA: function () {
      if (this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    programmerB: function () {
      if (this.get('permission') === "PROGRAMMER_B" || this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    actions: {
      setSearchStatus: function setSearchStatus(value) {
        var token = this.get('authenticationService').get();
        this.set('searchStatus', value);
        this.set('loading', true);
        getLogs(this, token);
      },
      nextPage: function nextPage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') < this.get('logs.meta.totalPages')) {
          this.set('searchPage', this.get('searchPage') + 1);
        }
        getLogs(this, token);
      },
      beforePage: function beforePage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') > 1) {
          this.set('searchPage', this.get('searchPage') - 1);
        }
        getLogs(this, token);
      },
      cleanTags: function cleanTags() {
        var token = this.get('authenticationService').get();
        this.set('searchStatus', null);

        this.set('loading', true);
        getLogs(this, token);
      },
      keyUpSearch: function keyUpSearch() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getLogs(this, token);
      }
    }

  });
});