define('ember-js-getting-started/router', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('feedback');
    this.route('page-not-found', { path: '*path' });

    this.route('panel', function () {
      this.route('general');
      this.route('customer');
      this.route('product');
      this.route('cron');
      this.route('user');
      this.route('order');
      this.route('graph');
      this.route('user-area');
      this.route('ticket');
      this.route('logs');
      this.route('tool');
      this.route('first-access');
      this.route('learn');
      this.route('knowledge');
      this.route('billet');
    });
  });

  exports.default = Router;
});