define('ember-js-getting-started/components/tool-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var clearForm = function clearForm(self) {
    self.set('name', null);
    self.set('description', null);
    self.set('url', null);
  };

  var validateForm = function validateForm(self) {
    if (self.get('name') === null || self.get('name') === undefined || self.get('name') === " " || self.get('name') === "") {
      alert('campo Nome é obrigatório');
      return false;
    }

    if (self.get('description') === null || self.get('description') === undefined || self.get('description') === " " || self.get('description') === "") {
      alert('campo Descrição Completo é obrigatório');
      return false;
    }

    return true;
  };

  var getTools = function getTools(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/tools/paginate?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&search=' + self.get('search'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('tools', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),
    utilService: Ember.inject.service('util-service'),

    loading: true,

    searchPerPage: 10,
    searchPage: 1,
    search: null,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('permission', this.get('authenticationService').get().extras.user.permissions[0].key);
      getTools(this, token);
    }),

    permission: function () {
      return null;
    }.property('permission'),

    //##########
    //permissões
    //##########
    master: function () {
      if (this.get('permission') === "MASTER") return true;else return false;
    }.property('master'),

    operatorA: function () {
      if (this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorA'),

    operatorB: function () {
      if (this.get('permission') === "OPERATOR_B" || this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorB'),

    designerA: function () {
      if (this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    designerB: function () {
      if (this.get('permission') === "DESIGNER_B" || this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    programmerA: function () {
      if (this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    programmerB: function () {
      if (this.get('permission') === "PROGRAMMER_B" || this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    actions: {
      keyUpSearch: function keyUpSearch() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getTools(this, token);
      },
      showNewToolsModal: function showNewToolsModal() {
        clearForm(this);
        $("#newToolsModal").modal('show');
      },
      delete: function _delete(tool) {
        var token = this.get('authenticationService').get();
        var self = this;

        if (!confirm("Tem certeza que deseja excluir esta ferramenta?")) {
          return;
        }

        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/tools/' + tool.id,
          type: 'DELETE',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            if (response.code !== 0) {
              alert('Excluido!');

              $("#showTookModal").modal('hide');

              getTools(self, token);
            } else {
              alert('Erro: ' + response.message);
            }
          },
          failure: function failure(response) {
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('loading', false);
            console.log(response);
          }
        });
      },
      save: function save() {
        var token = this.get('authenticationService').get();
        var submit = validateForm(this);
        var self = this;

        if (submit === true) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/tools',
            type: 'POST',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: '{"name":"' + self.get('name') + '",' + '"description":"' + self.get('description') + '",' + '"url":"' + self.get('url') + '"}',
            success: function success(response) {
              self.set('loading', false);
              getTools(self, token);
              $("#newToolsModal").modal('hide');
            },
            failure: function failure(response) {
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      nextPage: function nextPage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') < this.get('tools.meta.totalPages')) {
          this.set('searchPage', this.get('searchPage') + 1);
        }
        getTools(this, token);
      },
      beforePage: function beforePage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') > 1) {
          this.set('searchPage', this.get('searchPage') - 1);
        }
        getTools(this, token);
      },
      goTo: function goTo(url) {
        if (url !== 'null' && url !== undefined && url !== "" && url !== " ") {
          var win = window.open(url, '_blank');
          win.focus();
        } else {
          alert("Esta ferramenta não possui uma url cadastrada.");
        }
      },
      open: function open(tool) {
        this.set('showTool', tool);
        $("#showTookModal").modal('show');
      }
    }
  });
});