define('ember-js-getting-started/helpers/billet-status-tag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.billetStatusTag = billetStatusTag;
  function billetStatusTag(params /*, hash*/) {
    var status = params[0];

    if (status === "new") {
      return '<span class="badge badge-primary">Processando <i class="fa fa-spinner fa-spin" style="font-size:13px;color:white;"/></span> ';
    }

    if (status === "waiting") {
      return '<span class="badge badge-warning">Aguardando Pagamento</span> ';
    }

    if (status === "paid") {
      return '<span class="badge badge-success">Pago</span> ';
    }

    if (status === "unpaid") {
      return '<span class="badge badge-danger">Não Pago</span> ';
    }

    return 'INVALID';
  }

  exports.default = Ember.Helper.helper(billetStatusTag);
});