define('ember-js-getting-started/helpers/ticket-alert', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ticketAlert = ticketAlert;
  function ticketAlert(params /*, hash*/) {
    var ticket = params[0];

    var backValue = null;

    if (ticket.status === 'PENDENTE') {
      Ember.$.ajax({
        url: _environment.default.APP.sgplServiceUrl + '/tickets/' + ticket.id + '/verifyAlert',
        type: 'GET',
        dataType: 'json',
        async: false,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        data: null,
        success: function success(response) {
          if (response.message === "true") {
            backValue = '<span class="badge badge-danger fa-blink" style="font-size:16px;"><i class="fas fa-radiation-alt" style="font-size:16px;"></i> <span style="font-size:13px;">Atualizar</span></span>';
          }
        },
        failure: function failure(response) {
          console.log(response);
        },
        error: function error(response) {
          console.log(response);
        }
      });
    }

    return backValue;
  }

  exports.default = Ember.Helper.helper(ticketAlert);
});