define('ember-js-getting-started/components/billet-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var _refresh = function _refresh(self) {
    var token = self.get('authenticationService').get();
    self.set('loading', true);
    getBarcodes(self, token);
  };

  var getBarcodes = function getBarcodes(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/gerencianet/paginate?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&search=' + self.get('search'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('barcodes', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  var getOrders = function getOrders(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/orders/paginated?' + '&page=' + 1 + '&perPage=' + 10 + '&searchText=' + self.get('searchOrder') + '&activated=null&productName=null&expired=null',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loadingSearchOrder', false);
        self.set('orders', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),

    loading: true,

    searchPerPage: 10,
    searchPage: 1,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('permission', this.get('authenticationService').get().extras.user.permissions[0].key);
      getBarcodes(this, token);

      //TIMER para atualizar os dados da grid
      var handler;
      var self = this;
      handler = setInterval(function () {
        if (Ember.getOwner(self).lookup('controller:application').currentPath === 'panel.billet') {
          console.log('clock: ' + new Date());
          _refresh(self);
        }
      }, 180000); //60000 1 minutos

      this.set('handler', handler);
    }),

    //##########
    //permissões
    //##########
    master: function () {
      if (this.get('permission') === "MASTER") return true;else return false;
    }.property('master'),

    operatorA: function () {
      if (this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorA'),

    operatorB: function () {
      if (this.get('permission') === "OPERATOR_B" || this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorB'),

    designerA: function () {
      if (this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    designerB: function () {
      if (this.get('permission') === "DESIGNER_B" || this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    programmerA: function () {
      if (this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    programmerB: function () {
      if (this.get('permission') === "PROGRAMMER_B" || this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    actions: {
      nextPage: function nextPage() {
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') < this.get('barcodes.meta.totalPages')) {
          this.set('searchPage', this.get('searchPage') + 1);
        }
        this.set('loading', true);
        getBarcodes(this, token);
      },
      beforePage: function beforePage() {
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') > 1) {
          this.set('searchPage', this.get('searchPage') - 1);
        }
        this.set('loading', true);
        getBarcodes(this, token);
      },
      openBarcodeModal: function openBarcodeModal(barcode) {
        if (barcode.status === 'new') {
          alert('Aguarde, este boleto ainda não foi processado pelo servidor! Tente novamente daqui alguns minutos.');
          return;
        }

        this.set('showBc', barcode);
        $("#barcodeModal").modal('show');
      },
      openNewBarcodeModal: function openNewBarcodeModal() {
        this.set('description', null);
        this.set('value', null);
        this.set('days', null);
        this.set('searchOrder', null);
        this.set('idOrder', null);
        $("#newBarcodeModal").modal('show');
      },
      refresh: function refresh() {
        _refresh(this);
      },
      keyUpSearchBarcode: function keyUpSearchBarcode() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getBarcodes(this, token);
      },
      save: function save() {
        var token = this.get('authenticationService').get();
        var self = this;

        if (self.get('description') === null || self.get('description') === undefined || self.get('description') === "" || self.get('description') === " ") {
          alert('Campo Descrição é obrigatório.');
          return;
        }

        if (self.get('value') === null || self.get('value') === undefined || self.get('value') === "" || self.get('value') === " ") {
          alert('Campo Preço é obrigatório.');
          return;
        }

        if (self.get('days') === null || self.get('days') === undefined || self.get('days') === "" || self.get('days') === " ") {
          alert('Campo Dias é obrigatório.');
          return;
        }

        if (self.get('idOrder') === null || self.get('idOrder') === undefined || self.get('idOrder') === "" || self.get('idOrder') === " ") {
          alert('Campo Id Do Pedido é obrigatório.');
          return;
        }

        self.set('loadingSaving', true);
        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/gerencianet/charge/token',
          type: 'GET',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          data: null,
          success: function success(response) {
            self.set('gerencianetToken', response);

            Ember.$.ajax({
              url: _environment.default.APP.sgplServiceUrl + '/gerencianet/charge',
              type: 'POST',
              dataType: 'json',
              async: true,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Auth': token.token
              },
              data: '{"description":"' + self.get('description') + '",' + '"token":"' + self.get('gerencianetToken') + '",' + '"value":"' + self.get('value') + '"}',

              success: function success(response) {

                self.set('newChargeId', response.data.charge_id);

                Ember.$.ajax({
                  url: _environment.default.APP.sgplServiceUrl + '/gerencianet/charge/pay',
                  type: 'POST',
                  dataType: 'json',
                  async: false,
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Auth': token.token
                  },
                  data: '{"idOrder":"' + self.get('idOrder') + '",' + '"chargeId":"' + self.get('newChargeId') + '",' + '"token":"' + self.get('gerencianetToken') + '",' + '"expireDays":"' + self.get('days') + '"}',

                  success: function success(response) {
                    alert('Novo boleto criado, verifique na listagem!');
                    self.set('loadingSaving', false);
                    $("#newBarcodeModal").modal('hide');
                    getBarcodes(self, token);
                    _refresh(self);
                  },
                  failure: function failure(response) {
                    alert('Erro: ' + response.responseJSON.message);
                    alert('Provavelmente este erro está relacionado ao cadastro do cliente, verifique se o TELEFONE e/ou CPF e/ou CNPJ estão válidos. Em ultimo caso chame o Responsável pela integração do sistema.');
                    self.set('loading', false);
                    self.set('loadingSaving', false);
                    console.log(response);
                  },
                  error: function error(response) {
                    alert('Erro: ' + response.responseJSON.message);
                    alert('Provavelmente este erro está relacionado ao cadastro do cliente, verifique se o TELEFONE e/ou CPF e/ou CNPJ estão válidos. Em ultimo caso chame o Responsável pela integração do sistema.');
                    self.set('loading', false);
                    self.set('loadingSaving', false);
                    console.log(response);
                  }
                });
              },
              failure: function failure(response) {
                alert('Erro: ' + response.responseJSON.message);
                alert('Provavelmente este erro está relacionado ao cadastro do cliente, verifique se o TELEFONE e/ou CPF e/ou CNPJ estão válidos.  Em ultimo caso chame o Responsável pela integração do sistema.');
                self.set('loading', false);
                self.set('loadingSaving', false);
                console.log(response);
              },
              error: function error(response) {
                alert('Erro: ' + response.responseJSON.message);
                alert('Provavelmente este erro está relacionado ao cadastro do cliente, verifique se o TELEFONE e/ou CPF e/ou CNPJ estão válidos.  Em ultimo caso chame o Responsável pela integração do sistema.');
                self.set('loading', false);
                self.set('loadingSaving', false);
                console.log(response);
              }
            });
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            alert('Provavelmente este erro está relacionado ao cadastro do cliente, verifique se o TELEFONE e/ou CPF e/ou CNPJ estão válidos.  Em ultimo caso chame o Responsável pela integração do sistema.');
            self.set('loading', false);
            self.set('loadingSaving', false);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            alert('Provavelmente este erro está relacionado ao cadastro do cliente, verifique se o TELEFONE e/ou CPF e/ou CNPJ estão válidos.  Em ultimo caso chame o Responsável pela integração do sistema.');
            self.set('loading', false);
            self.set('loadingSaving', false);
            console.log(response);
          }
        });
      },
      save2: function save2() {
        var token = this.get('authenticationService').get();
        var self = this;

        if (self.get('description') === null || self.get('description') === undefined || self.get('description') === "" || self.get('description') === " ") {
          alert('Campo Descrição é obrigatório.');
          return;
        }

        if (self.get('value') === null || self.get('value') === undefined || self.get('value') === "" || self.get('value') === " ") {
          alert('Campo Preço é obrigatório.');
          return;
        }

        if (self.get('days') === null || self.get('days') === undefined || self.get('days') === "" || self.get('days') === " ") {
          alert('Campo Dias é obrigatório.');
          return;
        }

        if (self.get('idOrder') === null || self.get('idOrder') === undefined || self.get('idOrder') === "" || self.get('idOrder') === " ") {
          alert('Campo Id Do Pedido é obrigatório.');
          return;
        }

        self.set('loadingSaving', true);
        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/widepay/charge/pay',
          type: 'POST',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          data: '{"idOrder":"' + self.get('idOrder') + '",' + '"chargeId":"",' + '"expireDays":"' + self.get('days') + '",' + '"description":"' + self.get('description') + '",' + '"value":"' + self.get('value') + '"}',

          success: function success(response) {
            alert('Novo boleto criado, verifique na listagem!');
            self.set('loadingSaving', false);
            $("#newBarcodeModal").modal('hide');
            getBarcodes(self, token);
            _refresh(self);
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            alert('Provavelmente este erro está relacionado ao cadastro do cliente, verifique se o TELEFONE e/ou CPF e/ou CNPJ estão válidos. Em ultimo caso chame o Responsável pela integração do sistema.');
            self.set('loading', false);
            self.set('loadingSaving', false);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            alert('Provavelmente este erro está relacionado ao cadastro do cliente, verifique se o TELEFONE e/ou CPF e/ou CNPJ estão válidos. Em ultimo caso chame o Responsável pela integração do sistema.');
            self.set('loading', false);
            self.set('loadingSaving', false);
            console.log(response);
          }
        });
      },
      keyUpSearchOrder: function keyUpSearchOrder() {
        this.set('loadingSearchOrder', true);
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      setSearchOrder: function setSearchOrder(id) {
        this.set('idOrder', id);
        this.set('searchOrder', id);
        this.set('orders', null);
      }
    }

  });
});