define('ember-js-getting-started/components/order-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validateShowWhatsmakerAddCredits = function validateShowWhatsmakerAddCredits(self) {
    if (self.get('addWhatsmakerCreditsValue') === null || self.get('addWhatsmakerCreditsValue') === undefined || self.get('addWhatsmakerCreditsValue') === " " || self.get('addWhatsmakerCreditsValue') === "") {
      alert('campo Quantidade é obrigatório.');
      return false;
    }

    if (self.get('selectedWhatsmakerCreditTypeShow') === null || self.get('selectedWhatsmakerCreditTypeShow') === undefined || self.get('selectedWhatsmakerCreditTypeShow') === " " || self.get('selectedWhatsmakerCreditTypeShow') === "") {
      alert('é preciso escolher o Tipo do Crédito.');
      return false;
    }

    if (self.get('selectedActionWhatsmakerCredits') === null || self.get('selectedActionWhatsmakerCredits') === undefined || self.get('selectedActionWhatsmakerCredits') === " " || self.get('selectedActionWhatsmakerCredits') === "") {
      alert('é preciso escolher o a Ação a ser executada.');
      return false;
    }

    return true;
  };

  var validateShowWhatsmakerEditMachine = function validateShowWhatsmakerEditMachine(self) {
    if (self.get('addWhatsmakerMachinesValue') === null || self.get('addWhatsmakerMachinesValue') === undefined || self.get('addWhatsmakerMachinesValue') === " " || self.get('addWhatsmakerMachinesValue') === "") {
      alert('campo Quantidade é obrigatório.');
      return false;
    }

    if (self.get('selectedActionWhatsmakerMachine') === null || self.get('selectedActionWhatsmakerMachine') === undefined || self.get('selectedActionWhatsmakerMachine') === " " || self.get('selectedActionWhatsmakerMachine') === "") {
      alert('é preciso escolher o a Ação a ser executada.');
      return false;
    }

    return true;
  };

  var isWhatsmakerPlan = function isWhatsmakerPlan(self, order) {
    if (order.productLicense.product.name === "Socialmaker") {
      self.set('isWhatsmakerPlan', true);
    } else {
      self.set('isWhatsmakerPlan', false);
    }
  };

  var isSocialeadsPlan = function isSocialeadsPlan(self, order) {
    if (order.productLicense.product.name.includes("Socialeads")) {
      self.set('isSocialeadsPlan', true);
    } else {
      self.set('isSocialeadsPlan', false);
    }
  };

  var validateNewOrderForm = function validateNewOrderForm(self) {
    if (self.get('orderEmail') === null || self.get('orderEmail') === undefined || self.get('orderEmail') === " " || self.get('orderEmail') === "") {
      alert('campo E-mail é obrigatório');
      return false;
    }

    if (!self.get('utilService').isEmail(self.get('orderEmail'))) {
      alert('digite ume E-mail válido');
      return false;
    }

    if (self.get('createOrderSelectedProdId') === null || self.get('createOrderSelectedProdId') === undefined || self.get('createOrderSelectedProdId') === " " || self.get('createOrderSelectedProdId') === "") {
      alert('é preciso selecionar um produto.');
      return false;
    }

    if (self.get('createOrderBoolValidityDays') === true) {
      if (self.get('createOrderQuantityValidityDays') === null || self.get('createOrderQuantityValidityDays') === undefined || self.get('createOrderQuantityValidityDays') === " " || self.get('createOrderQuantityValidityDays') === "" || self.get('createOrderQuantityValidityDays') == 0) {
        alert('coloque um prazo de dias válido, valor numérico maior que 0.');
        return false;
      }
    }

    return true;
  };

  var getCieloPayments = function getCieloPayments(self, token, order) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/cfwhatsOrders/cielo/order/' + order.id,
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {

        self.set('cielo', response);

        self.set('loadingCieloDetails', false);

        //variavel de controle para o modal da cielo
        if (response !== null && response !== undefined) {
          if (response.recurrentPayment !== null && response.recurrentPayment !== undefined) {
            if (response.recurrentPayment.Status === 1) {
              self.set('cieloModalAtiveStatusControl', true);
            } else {
              self.set('cieloModalAtiveStatusControl', false);
            }
          }
        }
      },
      failure: function failure(response) {
        console.log(response);
      },
      error: function error(response) {
        console.log(response);
      }
    });
  };

  var getOrders = function getOrders(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/orders/paginated?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&searchText=' + self.get('search') + '&activated=' + self.get('activated') + '&productName=' + self.get('productName') + '&expired=' + self.get('expired') + '&contract=' + self.get('filterContractOrders'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('orders', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  var getProducts = function getProducts(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/products/all',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('productLoading', false);
        self.set('products', response);
      },
      failure: function failure(response) {
        self.set('productLoading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('productLoading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),
    utilService: Ember.inject.service('util-service'),

    loading: true,
    loadingCancelation: false,
    productLoading: false,

    searchPerPage: 10,
    searchPage: 1,
    search: null,
    activated: true,
    expired: false,

    productFiltered: "Todos Produtos",
    productName: null,

    filterContractOrders: true, // assinaturas = true , manuais = false

    //cadastro de pedidos (variaveis de controle)
    createOrderSelectedProdId: null,
    createOrderSelectedProdName: null,
    createOrderBoolValidityDays: false,
    createOrderQuantityValidityDays: null,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('permission', this.get('authenticationService').get().extras.user.permissions[0].key);
      getOrders(this, token);
    }),

    //##########
    //permissões
    //##########
    master: function () {
      if (this.get('permission') === "MASTER") return true;else return false;
    }.property('master'),

    operatorA: function () {
      if (this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorA'),

    operatorB: function () {
      if (this.get('permission') === "OPERATOR_B" || this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorB'),

    designerA: function () {
      if (this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    designerB: function () {
      if (this.get('permission') === "DESIGNER_B" || this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    programmerA: function () {
      if (this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    programmerB: function () {
      if (this.get('permission') === "PROGRAMMER_B" || this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    orders: function () {
      return null;
    }.property('orders'),

    products: function () {
      return null;
    }.property('products'),

    actions: {
      removeExpired: function removeExpired(pName, idOrder) {
        var self = this;

        var token = this.get('authenticationService').get();

        if (pName !== 'Socialmaker' && pName !== 'Zapmart') {
          alert("Este produto ainda não possui esta função.");
          return;
        }

        if (confirm("Tem certeza que deseja remover o status Atrasado manualmente?")) {
          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/orders/' + idOrder + '/removeExpired',
            type: 'PUT',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            success: function success(response) {
              self.set('showOrder.customExpired', false);
              alert("Feito! Deu tudo certo!");
              console.log(response);
            },
            failure: function failure(response) {
              alert("failure:" + response);
              console.log(response);
            },
            error: function error(response) {
              alert("error:" + response);
              console.log(response);
            }
          });
        }
      },
      saveNewOrder: function saveNewOrder() {
        var submit = validateNewOrderForm(this);
        var token = this.get('authenticationService').get();
        var self = this;

        if (self.get('createOrderSelectedProdId') !== 5 && self.get('createOrderSelectedProdId') !== 11 && self.get('createOrderSelectedProdId') !== 12 && self.get('createOrderSelectedProdId') !== 13 && self.get('createOrderSelectedProdId') !== 14 && self.get('createOrderSelectedProdId') !== 3 && self.get('createOrderSelectedProdId') !== 16 && self.get('createOrderSelectedProdId') !== 18 && self.get('createOrderSelectedProdId') !== 19 && self.get('createOrderSelectedProdId') !== 31) {
          alert('Essa função está disponível apenas para o SOCIALMAKER, SOCIALEADS, TAGCLICK, PAPAZAP e ZAPMART');
          return;
        }

        if (submit === true) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/orders/sgpl2',
            type: 'POST',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: '{"customerEmail":"' + self.get('orderEmail') + '",' + '"idProduct":"' + self.get('createOrderSelectedProdId') + '",' + '"vigencyDays":"' + self.get('createOrderQuantityValidityDays') + '"}',
            success: function success(response) {
              self.set('loading', false);
              if (response.code === 1) {
                alert('Cadastrado com sucesso!');
                //LIMPA FORMULARIO
                self.set('createOrderSelectedProdId', null);
                self.set('createOrderSelectedProdName', null);
                self.set('createOrderBoolValidityDays', false);
                self.set('createOrderQuantityValidityDays', null);
                self.set('orderEmail', null);
                //BUSCA CLIENTES
                getOrders(self, token);
                //fecha o modal
                $("#createOrderModal").modal('hide');
              } else {
                alert('Erro: ' + response.message);
              }
            },
            failure: function failure(response) {
              self.set('loading', false);
              alert('Erro: ' + response.responseJSON.message);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              alert('Erro: ' + response.responseJSON.message);
              console.log(response);
            }
          });
        }
      },
      keyUpValidityDays: function keyUpValidityDays(e) {
        this.set('createOrderQuantityValidityDays', this.get('utilService').onlyNumber(this.get('createOrderQuantityValidityDays')));
      },
      selectCreateOrderBoolValidityDays: function selectCreateOrderBoolValidityDays(bool) {
        if (bool === true) this.set('createOrderBoolValidityDays', true);else this.set('createOrderBoolValidityDays', false);
      },
      selectCreateOrderProduct: function selectCreateOrderProduct(product) {
        this.set('createOrderSelectedProdId', product.id);
        this.set('createOrderSelectedProdName', product.name);
      },
      showCreateOrderModal: function showCreateOrderModal() {
        var token = this.get('authenticationService').get();
        this.set('search', null);
        this.set('productLoading', true);
        getProducts(this, token);
        $("#createOrderModal").modal('show');
      },
      filterBySocialmaker: function filterBySocialmaker() {
        this.set('loading', true);
        this.set('productFiltered', '/images/ico-whatsmaker.png');
        this.set('productName', 'SOCIALMAKER');
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      filterByTagclick: function filterByTagclick() {
        this.set('loading', true);
        this.set('productFiltered', '/images/ico-tagclick.png');
        this.set('productName', 'TAGCLICK');
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      filterByPapazap: function filterByPapazap() {
        this.set('loading', true);
        this.set('productFiltered', '/images/ico-tagclick.png');
        this.set('productName', 'PAPAZAP');
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      filterByZapmart: function filterByZapmart() {
        this.set('loading', true);
        this.set('productFiltered', '/images/ico-tagclick.png');
        this.set('productName', 'ZAPMART');
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      filterByFollowshop: function filterByFollowshop() {
        this.set('loading', true);
        this.set('productFiltered', '/images/ico-tagclick.png');
        this.set('productName', 'FOLLOWSHOP');
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      filterByAllProducts: function filterByAllProducts() {
        this.set('loading', true);
        this.set('productFiltered', 'Todos Produtos');
        this.set('productName', null);
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      filterBySocialeads: function filterBySocialeads() {
        this.set('loading', true);
        this.set('productFiltered', '/images/ico-forgram.png');
        this.set('productName', 'SOCIALEADS');
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      filterByTikster: function filterByTikster() {
        this.set('loading', true);
        this.set('productFiltered', '/images/ico-forgram.png');
        this.set('productName', 'TIKSTER');
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      filterByDalego: function filterByDalego() {
        this.set('loading', true);
        this.set('productFiltered', '/images/ico-forgram.png');
        this.set('productName', 'DALEGO');
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      getExpiredOrders: function getExpiredOrders() {
        this.set('loading', true);

        if (this.get('expired') === true) this.set('expired', false);else this.set('expired', true);

        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      getManualOrders: function getManualOrders() {
        this.set('loading', true);
        this.set('filterContractOrders', false);
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      getContractOrders: function getContractOrders() {
        this.set('loading', true);
        this.set('filterContractOrders', true);
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      getVigencyOrders: function getVigencyOrders() {
        this.set('loading', true);
        this.set('activated', true);
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      getCanceledOrders: function getCanceledOrders() {
        this.set('loading', true);
        this.set('activated', false);
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      keyUpSearchOrder: function keyUpSearchOrder() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getOrders(this, token);
      },
      openCancelOrderModal: function openCancelOrderModal(id) {
        $("#showCancelOrderModal").modal('show');
      },
      cancelOrder: function cancelOrder(id) {
        var self = this;
        var token = self.get('authenticationService').get();

        $("#showCancelOrderModal").modal('hide');
        $("#showOrderModal").modal('hide');

        self.set('loadingCancelation', true);
        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/orders/' + id,
          type: 'DELETE',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          data: null,
          success: function success(response) {
            alert('cancelado com sucesso!');
            self.set('loadingCancelation', false);
            self.set('loading', false);
            self.set('showOrder.activated', false);
            self.get('orders').popObject(self.get('showOrder'));
          },
          failure: function failure(response) {
            self.set('loadingCancelation', false);
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('loadingCancelation', false);
            self.set('loading', false);
            console.log(response);
          }
        });
      },
      slDayMax: function slDayMax() {
        if (this.get('slDay') > 2) {
          this.set('slDay', this.get('slDay').slice(0, 2));
        }
      },
      slMonthMax: function slMonthMax() {
        if (this.get('slMonth') > 2) {
          this.set('slMonth', this.get('slMonth').slice(0, 2));
        }
      },
      slYearMax: function slYearMax() {
        if (this.get('slYear') > 4) {
          this.set('slYear', this.get('slYear').slice(0, 4));
        }
      },
      openResendSocialeadsQueueModal: function openResendSocialeadsQueueModal(order) {
        this.set('socialeadsResendOrder', order);
        $("#resendSocialeadsQueueModal").modal('show');
      },
      socialeadsResendQueue: function socialeadsResendQueue() {
        var self = this;

        var token = self.get('authenticationService').get();

        if (this.get('slDay') === null || this.get('slMonth') === null || this.get('slYear') === null || this.get('startHr') === null || this.get('slDay') === undefined || this.get('slMonth') === undefined || this.get('slYear') === undefined || this.get('startHr') === undefined) {
          alert('preencha os campos corretamente!');
          return;
        } else {

          if (this.get('slDay.length') < 2) {
            this.set('slDay', "0" + this.get('slDay'));
          }

          if (this.get('slDay') > 31) {
            return alert('dia inválido');
          }

          if (this.get('slMonth.length') < 2) {
            this.set('slMonth', "0" + this.get('slMonth'));
          }

          if (this.get('slMonth') > 12) {
            return alert('mês inválido');
          }

          if (this.get('slYear.length') < 4 || this.get('slYear') < 2021) {
            alert('ano inválido');
          } else {
            if (confirm("tem certeza que deseja reprocessar a fila de " + this.get('socialeadsResendOrder.hotmartRef') + " A partir de " + this.get('slDay') + "/" + this.get('slMonth') + "/" + this.get('slYear') + " " + this.get('startHr') + "? Essa ação não tem mais volta e exige um alto grau de responsabilidade.")) {
              Ember.$.ajax({
                url: _environment.default.APP.sgplServiceUrl + '/orders/socialeads/' + this.get('socialeadsResendOrder.hotmartRef') + "/" + this.get('slYear') + "-" + this.get('slMonth') + "-" + this.get('slDay') + " " + this.get('startHr') + ":00",
                type: 'GET',
                dataType: 'json',
                async: true,
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Auth': token.token
                },
                data: null,
                success: function success(response) {
                  $("#resendSocialeadsQueueModal").modal('hide');
                  alert("Reprocessado com sucesso!");

                  self.set('slDay', null);
                  self.set('slMonth', null);
                  self.set('slYear', null);
                  self.set('startHr', null);
                },
                failure: function failure(response) {
                  console.log(response);
                },
                error: function error(response) {
                  console.log(response);
                }
              });
            }
          }
        }
      },
      openShowOrderModal: function openShowOrderModal(order) {
        var self = this;

        var token = self.get('authenticationService').get();

        var playChernobylBeep = document.getElementById("playChernobylBeep");

        isWhatsmakerPlan(this, order);
        isSocialeadsPlan(this, order);

        self.set('whatsmakerBlacklistDetail', null);

        if (self.get('isWhatsmakerPlan')) {
          self.set('loadingCredits', true);

          self.set('loadingCieloDetails', true);

          self.set('loadingWhatsmakerBlacklistDetails', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/cfwhatsOrders/credits/order/' + order.id,
            type: 'GET',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: null,
            success: function success(response) {
              self.set('credits', response.credits);
              self.set('cotas', response.cotas);
              self.set('machines', response.machines);

              self.set('loadingCredits', false);
            },
            failure: function failure(response) {
              console.log(response);
            },
            error: function error(response) {
              console.log(response);
            }
          });

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/cfwhatsOrders/cielo/order/' + order.id,
            type: 'GET',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: null,
            success: function success(response) {

              self.set('cielo', response);

              self.set('loadingCieloDetails', false);

              //variavel de controle para o modal da cielo
              if (response !== null && response !== undefined) {
                if (response.recurrentPayment !== null && response.recurrentPayment !== undefined) {
                  if (response.recurrentPayment.Status === 1) {
                    self.set('cieloModalAtiveStatusControl', true);
                  } else {
                    self.set('cieloModalAtiveStatusControl', false);
                  }
                }
              }
            },
            failure: function failure(response) {
              console.log(response);
            },
            error: function error(response) {
              console.log(response);
            }
          });

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/cfwhatsOrders/blacklist/order/' + order.id + '/detail',
            type: 'GET',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: null,
            success: function success(response) {
              //limpar o chernobyl em outros cassos
              self.set('whatsmakerBlacklistDetail', response);

              if (self.get('whatsmakerBlacklistDetail.relatedMachines')) {
                playChernobylBeep.play();
              }

              console.log(response);
              self.set('loadingWhatsmakerBlacklistDetails', false);
            },
            failure: function failure(response) {
              console.log(response);
            },
            error: function error(response) {
              console.log(response);
            }
          });
        }

        this.set('showOrder', order);
        $("#showOrderModal").modal('show');
      },
      nextPage: function nextPage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') < this.get('orders.meta.totalPages')) {
          this.set('searchPage', this.get('searchPage') + 1);
        }
        getOrders(this, token);
      },
      beforePage: function beforePage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') > 1) {
          this.set('searchPage', this.get('searchPage') - 1);
        }
        getOrders(this, token);
      },
      showWhatsmakerAddCredits: function showWhatsmakerAddCredits() {
        $('#showWhatsmakerAddCredits').modal('show');
      },
      showWhatsmakerEditMachine: function showWhatsmakerEditMachine() {
        $('#showWhatsmakerEditMachine').modal('show');
      },
      addWhatsmakerCreditsValueKeyUpOnlyNumber: function addWhatsmakerCreditsValueKeyUpOnlyNumber(e) {
        this.set('addWhatsmakerCreditsValue', this.get('utilService').onlyNumber(this.get('addWhatsmakerCreditsValue')));
      },
      addWhatsmakerMachinesValueKeyUpOnlyNumber: function addWhatsmakerMachinesValueKeyUpOnlyNumber(e) {
        this.set('addWhatsmakerMachinesValue', this.get('utilService').onlyNumber(this.get('addWhatsmakerMachinesValue')));
      },
      saveWhatsmakerAddCredits: function saveWhatsmakerAddCredits() {
        var self = this;

        var token = self.get('authenticationService').get();

        if (validateShowWhatsmakerAddCredits(self)) {

          self.set('loading', true);

          var method = 'addSendCredits';

          if (self.get('selectedActionWhatsmakerCredits') === 'Adicionar') {
            method = 'addSendCredits';
          }

          if (self.get('selectedActionWhatsmakerCredits') === 'Remover') {
            method = 'removeSendCredits';
          }

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/cfwhatsOrders/' + method,
            type: 'POST',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: '{"credits":"' + self.get('addWhatsmakerCreditsValue') + '",' + '"idOrder":"' + self.get('showOrder.id') + '",' + '"type":"' + self.get('selectedWhatsmakerCreditTypeForm') + '"}',
            success: function success(response) {
              self.set('loading', false);

              if (response.code === 1) {
                alert('créditos adicionados com sucesso!');

                if (self.get('selectedWhatsmakerCreditTypeForm') === 'credit') {
                  var credit1 = parseInt(self.get('addWhatsmakerCreditsValue'));
                  var credit2 = parseInt(self.get('credits'));

                  if (self.get('selectedActionWhatsmakerCredits') === 'Adicionar') {
                    self.set('credits', credit1 + credit2);
                  } else {
                    self.set('credits', credit2 - credit1);
                  }
                }

                if (self.get('selectedWhatsmakerCreditTypeForm') === 'chatbot') {
                  var credit1 = parseInt(self.get('addWhatsmakerCreditsValue'));
                  var credit2 = parseInt(self.get('cotas'));

                  if (self.get('selectedActionWhatsmakerCredits') === 'Adicionar') {
                    self.set('cotas', credit1 + credit2);
                  } else {
                    self.set('cotas', credit2 - credit1);
                  }
                }

                self.set('addWhatsmakerCreditsValue', null);
                getOrders(self, token);
                //zerar o form
                self.set('selectedActionWhatsmakerCredits', null);
                self.set('addWhatsmakerCreditsValue', null);
                self.set('selectedWhatsmakerCreditTypeShow', null);

                //fecha o modal
                $("#showWhatsmakerAddCredits").modal('hide');
              } else {
                alert('Erro: ' + response.message);
              }
            },
            failure: function failure(response) {
              self.set('loading', false);
              alert('Erro: ' + response.responseJSON.message);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              alert('Erro: ' + response.responseJSON.message);
              console.log(response);
            }
          });
        }
      },
      saveWhatsmakerMachines: function saveWhatsmakerMachines() {
        var self = this;

        var token = self.get('authenticationService').get();

        if (validateShowWhatsmakerEditMachine(self)) {

          self.set('loading', true);

          var method = 'addMachine';

          if (self.get('selectedActionWhatsmakerMachine') === 'Adicionar') {
            method = 'addMachine';
          }

          if (self.get('selectedActionWhatsmakerMachine') === 'Remover') {
            method = 'removeMachine';
          }

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/cfwhatsOrders/' + method,
            type: 'POST',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: '{"credits":"' + self.get('addWhatsmakerMachinesValue') + '",' + '"idOrder":"' + self.get('showOrder.id') + '"}',
            success: function success(response) {
              self.set('loading', false);

              if (response.code === 1) {
                alert('operação executada sucesso!');

                if (self.get('selectedActionWhatsmakerMachine') === 'Adicionar') {
                  var credit1 = parseInt(self.get('addWhatsmakerMachinesValue'));
                  var credit2 = parseInt(self.get('machines'));
                  self.set('machines', credit1 + credit2);
                }

                if (self.get('selectedActionWhatsmakerMachine') === 'Remover') {
                  var credit1 = parseInt(self.get('addWhatsmakerMachinesValue'));
                  var credit2 = parseInt(self.get('machines'));
                  self.set('machines', credit2 - credit1);
                }

                self.set('addWhatsmakerMachinesValue', null);
                getOrders(self, token);

                //zerar o form
                self.set('selectedActionWhatsmakerMachine', null);
                self.set('addWhatsmakerMachinesValue', null);

                //fecha o modal
                $("#showWhatsmakerEditMachine").modal('hide');
              } else {
                alert('Erro: ' + response.message);
              }
            },
            failure: function failure(response) {
              self.set('loading', false);
              alert('Erro: ' + response.responseJSON.message);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              alert('Erro: ' + response.responseJSON.message);
              console.log(response);
            }
          });
        }
      },
      setTypeWhatsmakerCredit: function setTypeWhatsmakerCredit(type) {
        if (type === 'chatbot') this.set('selectedWhatsmakerCreditTypeShow', 'Chatbot');
        if (type === 'credit') this.set('selectedWhatsmakerCreditTypeShow', 'Envios');

        this.set('selectedWhatsmakerCreditTypeForm', type);
      },
      setActionWhatsmakerMachine: function setActionWhatsmakerMachine(key) {
        this.set('selectedActionWhatsmakerMachine', key);
      },
      setActionWhatsmakerCredits: function setActionWhatsmakerCredits(key) {
        this.set('selectedActionWhatsmakerCredits', key);
      },
      showWhatsmakerConsumesTrace: function showWhatsmakerConsumesTrace(prodName, idOrder) {
        var self = this;
        var token = self.get('authenticationService').get();

        if (prodName !== 'Socialmaker') {
          alert("Este produto ainda não possui esta função.");
          return;
        }

        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/cfwhatsOrders/consumesTrace/order/' + idOrder,
          type: 'GET',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            console.log(response);
            self.set('loading', false);
            self.set('consumesList', response);
            $('#showConsumesTraceModal').modal('show');
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          }
        });
      },
      showCustomerNfeList: function showCustomerNfeList(idCustomer) {
        var self = this;
        var token = self.get('authenticationService').get();

        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/customers/' + idCustomer + '/nfe',
          type: 'GET',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            console.log(response);
            self.set('loading', false);
            self.set('nfeList', response);
            $('#showCustomerNfeListModal').modal('show');
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          }
        });
      },
      showCieloDetails: function showCieloDetails(idCustomer) {
        console.log(this.get('cielo'));
        $('#showCieloDatailsModal').modal('show');
      },
      showBillingHistory: function showBillingHistory(prodName, idOrder) {
        var self = this;
        var token = self.get('authenticationService').get();

        if (prodName !== 'Socialmaker') {
          alert("Este produto ainda não possui esta função.");
          return;
        }

        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/cfwhatsOrders/billigHistory/order/' + idOrder,
          type: 'GET',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            console.log(response);
            self.set('loading', false);
            self.set('billingHistoryList', response);
            $('#showBillingHistoryModal').modal('show');
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          }
        });
      },
      resetPassword: function resetPassword(name, id) {
        var self = this;
        var token = self.get('authenticationService').get();
        var productApi = "";

        if (name !== 'Socialmaker' && name !== 'Tagclick' && name !== 'Zapmart') {
          alert("Este produto ainda não possui esta função.");
          return;
        }

        if (name === 'Socialmaker') {
          productApi = 'cfwhatsOrders';
        }

        if (name === 'Tagclick') {
          productApi = 'tagclick';
        }

        if (name === 'Zapmart') {
          productApi = 'zapmart';
        }

        if (confirm("Tem certeza que deseja prosseguir? A senha será resetada para: 44554466")) {

          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/' + productApi + '/resetPassword/order/' + id,
            type: 'PUT',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            success: function success(response) {
              if (response.code === 1) {
                self.set('loading', false);
                alert('operação executada sucesso!');
              } else {
                self.set('loading', false);
                alert('Erro: ' + response.message);
              }
            },
            failure: function failure(response) {
              alert('Erro: ' + response.responseJSON.message);
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              alert('Erro: ' + response.responseJSON.message);
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      updateEmail: function updateEmail(email, id, prodName) {
        var self = this;
        var token = self.get('authenticationService').get();

        if (prodName === 'Forgram') {
          alert("Não é possível trocar o email de clientes com este Produto.");
          return;
        }

        if (prodName === 'Connectface') {
          alert("Não é possível trocar o email de clientes com este Produto.");
          return;
        }

        if (prodName === 'Whatsmaker') {
          if (this.get('showOrder.hotmartRef') === null || this.get('showOrder.hotmartRef') === '') {
            alert("Para trocar o email de um cliente que possui o Whatsmaker, é necessário que o pedido já possua o código de assinatura da Hotmart. Caso contrário avisar o Pedro.");
            return;
          }
        }

        if (confirm("Tem certeza que deseja alterar o email deste cliente? Este email será alterado em todos os Produtos que tiver cadastro. Inclusive na emissão de Notas.")) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/customers/order/' + id + '/email/' + email,
            type: 'PUT',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            success: function success(response) {
              if (response.code === 1) {
                self.set('loading', false);
                alert('operação executada sucesso!');
              } else {
                if (response.code === 2) {
                  self.set('loading', false);
                  alert('email em uso!');
                } else {
                  self.set('loading', false);
                  alert('Erro: ' + response.message);
                }
              }
            },
            failure: function failure(response) {
              alert('Erro: ' + response.responseJSON.message);
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              alert('Erro: ' + response.responseJSON.message);
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      deactivateCielo: function deactivateCielo() {
        var self = this;
        var token = self.get('authenticationService').get();

        if (confirm("Tem certeza que deseja Desativar esta assinatura? As máquinas do pedido não serão removidas automáticamente, para isso, execute o processo manualmente.")) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/cielo/recurrentPayment/' + self.get('cielo.RecurrentPayment.RecurrentPaymentId') + '/deactivate',
            type: 'PUT',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            success: function success(response) {
              self.set('cielo.RecurrentPayment.Status', response.RecurrentPayment.Status);

              self.set('cieloModalAtiveStatusControl', false);

              alert('Operação executada com sucesso!');

              self.set('loading', false);
            },
            failure: function failure(response) {
              alert('Erro: ' + response.responseJSON.message);
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              alert('Erro: ' + response.responseJSON.message);
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      reactivateCielo: function reactivateCielo() {
        var self = this;
        var token = self.get('authenticationService').get();

        if (confirm("Tem certeza que deseja Reativar esta assinatura? As máquinas do pedido não serão adicionadas automáticamente, para isso, você deverá executar o processo manualmente.")) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/cielo/recurrentPayment/' + self.get('cielo.RecurrentPayment.RecurrentPaymentId') + '/reactivate',
            type: 'PUT',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            success: function success(response) {
              self.set('cielo.RecurrentPayment.Status', response.RecurrentPayment.Status);

              self.set('cieloModalAtiveStatusControl', true);

              alert('Operação executada com sucesso!');

              self.set('loading', false);
            },
            failure: function failure(response) {
              alert('Erro: ' + response.responseJSON.message);
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              alert('Erro: ' + response.responseJSON.message);
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      cancelCieloPayment: function cancelCieloPayment(paymentId, order) {
        var self = this;
        var token = self.get('authenticationService').get();

        if (confirm("Tem certeza que deseja estornar o pagamento " + paymentId + "? Este processo não poderá ser revertido e a Ordem de Serviço no sistema Omie deverá ser cancelada Manualmente!")) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/cielo/payment/' + paymentId + '/cancel',
            type: 'PUT',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            success: function success(response) {
              alert(response.ReturnMessage);

              getCieloPayments(self, token, order);

              self.set('loading', false);
            },
            failure: function failure(response) {
              alert('Erro: ' + response.responseJSON.message);
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              alert('Erro: ' + response.responseJSON.message);
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      changeShowCreditDeailList: function changeShowCreditDeailList() {
        if (this.get('cfwhatsCreditsDetailsList') === true) {
          this.set('cfwhatsCreditsDetailsList', false);
          this.set('cfwhatsCreditsDetailsButton', 'Créditos');
        } else {
          this.set('cfwhatsCreditsDetailsList', true);
          this.set('cfwhatsCreditsDetailsButton', 'Chatbot');
        }
      },
      showCreditsDetailsModal: function showCreditsDetailsModal(idCustomer) {
        var self = this;
        var token = self.get('authenticationService').get();

        self.set('loading', true);
        self.set('cfwhatsCreditsDetailsList', true);
        self.set('cfwhatsCreditsDetailsButton', 'Chatbot');

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/cfwhatsOrders/autoresponder/customer/' + idCustomer,
          type: 'GET',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            console.log(response);
            self.set('loading', false);
            self.set('autoresponderDetailsList', response);
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          }
        });

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/cfwhatsOrders/credits/customer/' + idCustomer,
          type: 'GET',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            console.log(response);
            self.set('loading', false);
            self.set('creditsDetailsList', response);
            $('#showCreditsDetailsModal').modal('show');
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          }
        });
      },
      showWhatsmakerBlacklistDetails: function showWhatsmakerBlacklistDetails() {
        this.set('whatsmakerStampValueSearchValue', null);
        $('#showWhatsmakerBlacklistDetailsModal').modal('show');
      },
      showWhatsmakerBkUsersMachineRelated: function showWhatsmakerBkUsersMachineRelated(stamp) {
        this.set('selectedRm', stamp);
        $('#showWhatsmakerBkUsersMachineRelatedModal').modal('show');
      },
      searchWhatsmakerStampReason: function searchWhatsmakerStampReason() {
        var self = this;
        var token = self.get('authenticationService').get();

        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/cfwhatsOrders/blacklist/machine/' + self.get('whatsmakerStampValueSearchValue'),
          type: 'GET',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            self.set('loading', false);
            self.set('whatsmakerStampFinded', response);
            $('#showWhatsmakerStampFindedModal').modal('show');
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          }
        });
      }
    }

  });
});