define('ember-js-getting-started/components/user-profile-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getUsers = function getUsers(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/users?' + '&page=1&perPage=1&searchText=' + self.get('userProfile.email'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('users', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({

    userInfoLoading: false,

    authenticationService: Ember.inject.service('auth-token-service'),

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('userProfile', this.get('authenticationService').get().extras.user);
      this.set('gravatar', this.get('authenticationService').get().gravatar);
      getUsers(this, token);
    }),

    gravatar: function () {
      return null;
    }.property('gravatar'),

    userProfile: function () {
      return null;
    }.property('userProfile')

  });
});