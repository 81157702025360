define("ember-js-getting-started/helpers/cielo-payment-status", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cieloPaymentStatus = cieloPaymentStatus;
  function cieloPaymentStatus(params /*, hash*/) {
    var value = params[0];

    if (value !== null && value !== "") {

      if (value === 0) return "Aguardando Atualização";

      if (value === 1) return "Authorizado";

      if (value === 2) return "Confirmado/Finalizado";

      if (value === 3) return "Negado";

      if (value === 10) return "Cancelado";

      if (value === 11) return "Cancelado";

      if (value === 12) return "Aguardando Atualização";

      if (value === 13) return "Cancelado";

      if (value === 20) return "Agendado";

      return null;
    }
    return null;
  }

  exports.default = Ember.Helper.helper(cieloPaymentStatus);
});