define('ember-js-getting-started/routes/index', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    authenticationService: Ember.inject.service('auth-token-service'),

    redirect: function redirect() {
      //para cliente abelhudo nao ter acesso ao sistema
      var pixelCustomer = this.get('authenticationService').getPxfbct();

      if (pixelCustomer === true) {
        window.location.replace("https://socialmaker.com.br/");
      }

      this.transitionTo('login');
    }
  });
});