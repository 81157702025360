define('ember-js-getting-started/components/cron-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getCron = function getCron(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/automaticRoutines?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&searchText=' + self.get('search'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('routines', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),

    loading: true,

    searchPerPage: 10,
    searchPage: 1,
    search: null,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      getCron(this, token);
    }),

    routines: function () {
      return null;
    }.property('routines'),

    actions: {
      keyUpSearchRoutine: function keyUpSearchRoutine() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getCron(this, token);
      },
      openShowRoutineModal: function openShowRoutineModal(routine) {
        console.log(routine);
        this.set('showRoutine', routine);
        $("#showRoutineModal").modal('show');
      },
      nextPage: function nextPage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') < this.get('routines.meta.totalPages')) {
          this.set('searchPage', this.get('searchPage') + 1);
        }
        getCron(this, token);
      },
      beforePage: function beforePage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') > 1) {
          this.set('searchPage', this.get('searchPage') - 1);
        }
        getCron(this, token);
      }
    }
  });
});