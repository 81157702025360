define("ember-js-getting-started/helpers/cielo-subscription-status", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.cieloSubscriptionStatus = cieloSubscriptionStatus;
  function cieloSubscriptionStatus(params /*, hash*/) {
    var value = params[0];

    if (value !== null && value !== "") {

      if (value === 1) return "Ativo";

      if (value === 2) return "Finalizado";

      if (value === 3) return "Desativada pelo Lojista";

      if (value === 4) return "Desativada por Retentativas";

      if (value === 5) return "Desativada por Cartão Vencido";

      return null;
    }

    return null;
  }

  exports.default = Ember.Helper.helper(cieloSubscriptionStatus);
});