define('ember-js-getting-started/helpers/mine-ticket', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mineTicket = mineTicket;
  function mineTicket(params /*, hash*/) {
    if (localStorage.getItem('sgpl-panel:authentication') !== null || localStorage.getItem('sgpl-panel:authentication') !== "") {
      var email = JSON.parse(localStorage.getItem('sgpl-panel:authentication')).extras.user.email;
    }

    var v = params[0];

    if (v === email) {
      return '<i class="fas fa-atom" style="font-size:15px;color:#FFD700;"></i>';
    } else {
      return null;
    }
  }

  exports.default = Ember.Helper.helper(mineTicket);
});