define('ember-js-getting-started/services/auth-token-service', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var checkStorage = function checkStorage() {
    if (localStorage.getItem('sgpl-panel:authentication') === null || localStorage.getItem('sgpl-panel:authentication') === "") {
      return false;
    }
    return true;
  };

  var checkPxfb = function checkPxfb() {
    if (localStorage.getItem('sgpl-panel:pxfb') === null || localStorage.getItem('sgpl-panel:pxfb') === "") {
      return false;
    }
    return true;
  };

  var checkPxfbct = function checkPxfbct() {
    if (localStorage.getItem('sgpl-panel:pxfbct') === null || localStorage.getItem('sgpl-panel:pxfbct') === "") {
      return false;
    }
    return true;
  };

  exports.default = Ember.Service.extend({
    logoff: function logoff() {
      localStorage.setItem('sgpl-panel:authentication', null);
    },
    login: function login(token) {
      localStorage.setItem('sgpl-panel:authentication', JSON.stringify(token));
      localStorage.setItem('sgpl-panel:pxfb', true);
    },
    get: function get() {
      if (checkStorage()) {
        return JSON.parse(localStorage.getItem('sgpl-panel:authentication'));
      }
    },
    getPxfb: function getPxfb() {
      if (checkPxfb()) {
        return JSON.parse(localStorage.getItem('sgpl-panel:pxfb'));
      }
    },
    getPxfbct: function getPxfbct() {
      if (checkPxfbct()) {
        return JSON.parse(localStorage.getItem('sgpl-panel:pxfbct'));
      }
    }
  });
});