define('ember-js-getting-started/helpers/ticket-channel-tag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ticketChannelTag = ticketChannelTag;
  function ticketChannelTag(params /*, hash*/) {
    var value = params[0];

    if (value === "CHAT") {
      return '<span class="badge badge-secondary">Chat</span> ';
    }

    if (value === "EMAIL") {
      return '<span class="badge badge-secondary">Email</span> ';
    }

    if (value === "WHATSAPP") {
      return '<span class="badge badge-secondary">Whatsapp</span> ';
    }

    return 'INVALID';
  }

  exports.default = Ember.Helper.helper(ticketChannelTag);
});