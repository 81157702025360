define('ember-js-getting-started/components/user-edit-pass-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var cleanForm = function cleanForm(self) {
    self.set('oldLoginPassword', '');
    self.set('loginPassword', '');
    self.set('loginPasswordConfirmation', '');
  };

  var freeEditButtom = function freeEditButtom(self) {
    if (self.get('oldLoginPassword') !== undefined && self.get('oldLoginPassword') !== '' && self.get('loginPassword') !== undefined && self.get('loginPassword') !== '' && self.get('loginPasswordConfirmation') !== undefined && self.get('loginPasswordConfirmation') !== '') {
      self.set('editPasswordButtom', true);
    } else {
      self.set('editPasswordButtom', false);
    }
  };

  var _editPassword = function editPassword(self, token) {
    var editPassword = JSON.stringify({ password: self.get('loginPassword'), passwordConfirmation: self.get('loginPasswordConfirmation'), oldPassword: self.get('oldLoginPassword') });
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/resetPasswords/editPassword',
      type: 'POST',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token
      },
      data: editPassword,
      success: function success(response) {
        self.set('spinner', false);
        if (response.code === 9) {
          self.set('messageResponse1', 'Senha Atual é inválida!');
          self.set('messageResponse2', '');
        } else {
          if (response.code === 8) {
            self.set('messageResponse1', 'a senha precisa ter no mínimo 6 caracteres.');
            self.set('messageResponse2', '');
          }
          self.set('messageResponse1', '');
          self.set('messageResponse2', 'Senha atualizada com sucesso!');

          cleanForm(self);
        }
      },
      failure: function failure(response) {
        self.set('spinner', false);
        self.set('messageResponse1', 'Senha não confere');
        console.log(response);
      },
      error: function error(response) {
        self.set('spinner', false);
        self.set('messageResponse1', 'Senha não confere');
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({

    authenticationService: Ember.inject.service('auth-token-service'),

    oldLoginPasswordObserver: Ember.on('init', Ember.observer('oldLoginPassword', function () {
      freeEditButtom(this);
    })),

    loginPasswordObserver: Ember.on('init', Ember.observer('loginPassword', function () {
      freeEditButtom(this);
    })),

    loginPasswordConfirmationObserver: Ember.on('init', Ember.observer('loginPasswordConfirmation', function () {
      freeEditButtom(this);
    })),

    spinner: false,

    editPasswordButtom: false,

    actions: {
      unblockButtom: function unblockButtom() {
        this.set('messageResponse1', 'Preencha os campos de senha corretamente');
        this.set('messageResponse2', '');
      },
      editPassword: function editPassword() {
        this.set('messageResponse1', '');
        this.set('messageResponse2', '');

        var authToken = this.get('authenticationService').get().token;
        this.set('spinner', true);
        if (this.get('oldLoginPassword') === undefined || this.get('oldLoginPassword') === '') {
          this.set('messageResponse1', 'Preencha a senha atual');
          this.set('spinner', false);
        } else {
          if (this.get('loginPassword') === undefined || this.get('loginPassword') === '' || this.get('loginPasswordConfirmation') === undefined || this.get('loginPasswordConfirmation') === '') {
            this.set('messageResponse1', 'Preencha os campos de senha corretamente');
            this.set('spinner', false);
          } else {
            if (this.get('loginPassword') !== this.get('loginPasswordConfirmation')) {
              this.set('messageResponse1', 'Senha e Confirmação de senha não conferem');
              this.set('spinner', false);
            } else {
              if (this.get('loginPassword').length < 6) {
                this.set('messageResponse1', 'Senha deve conter mais de 6 caracteres (Entre letras e números)');
                this.set('spinner', false);
              } else {
                _editPassword(this, authToken);
              }
            }
          }
        }
      }
    }
  });
});