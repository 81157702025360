define('ember-js-getting-started/routes/feedback', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    authenticationService: Ember.inject.service('auth-token-service'),

    beforeModel: function beforeModel() {
      var pixel = this.get('authenticationService').getPxfb();
      if (pixel === true) {
        alert("Você não tem permissão para acessar esta página.");
        this.transitionTo('login');
      } else {
        localStorage.setItem('sgpl-panel:pxfbct', true);
      }
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (window.location.href === 'http://localhost:4200/#/login' || window.location.href === 'https://sgpl.socialcompany.com.br/#/login' || window.location.href === 'http://sgpl.socialcompany.com.br/#/login') {
          this.transitionTo('feedback');
        }
      }
    }
  });
});