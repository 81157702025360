define('ember-js-getting-started/components/feedback-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var verifyAvaiability = function verifyAvaiability(self) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/tickets/' + self.get('idTicket') + '/feedbackAvaiability',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('avaiability', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    loading: true,

    initComponent: Ember.on('init', function () {
      var url_string = window.location.href;
      var ticket = url_string.split('?ticket=')[1];
      this.set('idTicket', ticket);
      verifyAvaiability(this);
    }),

    actions: {
      save: function save(note) {
        var self = this;

        if (confirm("Tem certeza que deseja enviar " + note + " estrelas para este atendimento?")) {
          self.set('loading', true);
          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/tickets/' + self.get('idTicket') + '/feedbackAvaiability/note/' + note,
            type: 'POST',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            data: null,
            success: function success(response) {
              alert('Obrigado por me avaliar!');
              self.set('loading', false);
              window.location.replace("https://socialmaker.com.br/");
            },
            failure: function failure(response) {
              self.set('loading', false);
              alert('Erro: ' + response.responseJSON.message);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              alert('Erro: ' + response.responseJSON.message);
              console.log(response);
            }
          });
        }
      }
    }
  });
});