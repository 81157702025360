define('ember-js-getting-started/components/customer-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var clearNewCustomerForm = function clearNewCustomerForm(self) {
    self.set('email', null);
    self.set('fullName', null);
    self.set('cpf', null);
    self.set('phone', null);
    self.set('cep', null);
    self.set('address', null);
    self.set('number', null);
    self.set('district', null);
    self.set('city', null);
    self.set('state', null);
    self.set('country', null);
    self.set('business', false);
    self.set('companyName', null);
    self.set('tradingName', null);
    self.set('cnpj', null);
  };

  var clearEditCustomerForm = function clearEditCustomerForm(self) {
    self.set('showCustomer.email', null);
    self.set('showCustomer.fullName', null);
    self.set('showCustomer.cpf', null);
    self.set('showCustomer.phone', null);
    self.set('showCustomer.psotalCode', null);
    self.set('showCustomer.street', null);
    self.set('showCustomer.number', null);
    self.set('showCustomer.district', null);
    self.set('showCustomer.city', null);
    self.set('showCustomer.state', null);
    self.set('showCustomer.country', null);
    self.set('showCustomer.business', null);
    self.set('showCustomer.companyName', null);
    self.set('showCustomer.tradingName', null);
    self.set('showCustomer.cnpj', null);
  };

  var validateEditCustomerForm = function validateEditCustomerForm(self) {
    if (self.get('showCustomer.email') === null || self.get('showCustomer.email') === undefined || self.get('showCustomer.email') === " " || self.get('showCustomer.email') === "") {
      alert('campo E-mail é obrigatório');
      return false;
    }

    if (self.get('showCustomer.fullName') === null || self.get('showCustomer.fullName') === undefined || self.get('showCustomer.fullName') === " " || self.get('showCustomer.fullName') === "") {
      alert('campo Nome Completo é obrigatório');
      return false;
    }

    if (self.get('showCustomer.cpf') === null || self.get('showCustomer.cpf') === undefined || self.get('showCustomer.cpf') === " " || self.get('showCustomer.cpf') === "") {
      alert('campo CPF é obrigatório');
      return false;
    }

    if (self.get('showCustomer.phone') === null || self.get('showCustomer.phone') === undefined || self.get('showCustomer.phone') === " " || self.get('showCustomer.phone') === "") {
      alert('campo Telefone é obrigatório');
      return false;
    }

    //endereço

    if (self.get('showCustomer.postalCode') === null || self.get('showCustomer.postalCode') === undefined || self.get('showCustomer.postalCode') === " " || self.get('showCustomer.postalCode') === "") {
      alert('campo CEP é obrigatório');
      return false;
    }

    if (self.get('showCustomer.street') === null || self.get('showCustomer.street') === undefined || self.get('showCustomer.street') === " " || self.get('showCustomer.street') === "") {
      alert('campo Endereço é obrigatório');
      return false;
    }

    if (self.get('showCustomer.number') === null || self.get('showCustomer.number') === undefined || self.get('showCustomer.number') === " " || self.get('showCustomer.number') === "") {
      alert('campo Número é obrigatório');
      return false;
    }

    if (self.get('showCustomer.district') === null || self.get('showCustomer.district') === undefined || self.get('showCustomer.district') === " " || self.get('showCustomer.district') === "") {
      alert('campo Bairro é obrigatório');
      return false;
    }

    if (self.get('showCustomer.complement') === null || self.get('showCustomer.complement') === undefined || self.get('showCustomer.complement') === " " || self.get('showCustomer.complement') === "") {
      self.set('showCustomer.complement', null);
    }

    if (self.get('showCustomer.city') === null || self.get('showCustomer.city') === undefined || self.get('showCustomer.city') === " " || self.get('showCustomer.city') === "") {
      alert('campo Cidade é obrigatório');
      return false;
    }

    if (self.get('showCustomer.state') === null || self.get('showCustomer.state') === undefined || self.get('showCustomer.state') === " " || self.get('showCustomer.state') === "") {
      alert('campo Estado é obrigatório');
      return false;
    }

    if (self.get('showCustomer.country') === null || self.get('showCustomer.country') === undefined || self.get('showCustomer.country') === " " || self.get('showCustomer.country') === "") {
      alert('campo País é obrigatório');
      return false;
    }

    //pessoa juridica

    if (self.get('showCustomer.companyName') === null || self.get('showCustomer.companyName') === undefined || self.get('showCustomer.companyName') === " " || self.get('showCustomer.companyName') === "") {
      self.set('showCustomer.companyName', null);
    }

    if (self.get('showCustomer.tradingName') === null || self.get('showCustomer.tradingName') === undefined || self.get('showCustomer.tradingName') === " " || self.get('showCustomer.tradingName') === "") {
      self.set('showCustomer.tradingName', null);
    }

    if (self.get('showCustomer.cnpj') === null || self.get('showCustomer.cnpj') === undefined || self.get('showCustomer.cnpj') === " " || self.get('showCustomer.cnpj') === "") {
      self.set('showCustomer.cnpj', null);
    }

    if (self.get('showCustomer.business') === true) {
      if (self.get('showCustomer.companyName') === null || self.get('showCustomer.companyName') === undefined || self.get('showCustomer.companyName') === " " || self.get('showCustomer.companyName') === "") {
        alert('campo Razão Social é obrigatório');
        return false;
      }

      if (self.get('showCustomer.tradingName') === null || self.get('showCustomer.tradingName') === undefined || self.get('showCustomer.tradingName') === " " || self.get('showCustomer.tradingName') === "") {
        alert('campo Nome Fantasia é obrigatório');
        return false;
      }

      if (self.get('showCustomer.cnpj') === null || self.get('showCustomer.cnpj') === undefined || self.get('showCustomer.cnpj') === " " || self.get('showCustomer.cnpj') === "") {
        alert('campo CNPJ é obrigatório');
        return false;
      }
    }

    return true;
  };

  var validateNewCustomerForm = function validateNewCustomerForm(self) {
    if (self.get('email') === null || self.get('email') === undefined || self.get('email') === " " || self.get('email') === "") {
      alert('campo E-mail é obrigatório');
      return false;
    }

    if (self.get('fullName') === null || self.get('fullName') === undefined || self.get('fullName') === " " || self.get('fullName') === "") {
      alert('campo Nome Completo é obrigatório');
      return false;
    }

    if (self.get('cpf') === null || self.get('cpf') === undefined || self.get('cpf') === " " || self.get('cpf') === "") {
      alert('campo CPF é obrigatório');
      return false;
    }

    if (self.get('phone') === null || self.get('phone') === undefined || self.get('phone') === " " || self.get('phone') === "") {
      alert('campo Telefone é obrigatório');
      return false;
    }

    //endereço

    if (self.get('cep') === null || self.get('cep') === undefined || self.get('cep') === " " || self.get('cep') === "") {
      alert('campo CEP é obrigatório');
      return false;
    }

    if (self.get('address') === null || self.get('address') === undefined || self.get('address') === " " || self.get('address') === "") {
      alert('campo Endereço é obrigatório');
      return false;
    }

    if (self.get('number') === null || self.get('number') === undefined || self.get('number') === " " || self.get('number') === "") {
      alert('campo Número é obrigatório');
      return false;
    }

    if (self.get('district') === null || self.get('district') === undefined || self.get('district') === " " || self.get('district') === "") {
      alert('campo Bairro é obrigatório');
      return false;
    }

    if (self.get('complement') === null || self.get('complement') === undefined || self.get('complement') === " " || self.get('complement') === "") {
      self.set('complement', null);
    }

    if (self.get('city') === null || self.get('city') === undefined || self.get('city') === " " || self.get('city') === "") {
      alert('campo Cidade é obrigatório');
      return false;
    }

    if (self.get('state') === null || self.get('state') === undefined || self.get('state') === " " || self.get('state') === "") {
      alert('campo Estado é obrigatório');
      return false;
    }

    if (self.get('country') === null || self.get('country') === undefined || self.get('country') === " " || self.get('country') === "") {
      alert('campo País é obrigatório');
      return false;
    }

    //pessoa juridica

    if (self.get('companyName') === null || self.get('companyName') === undefined || self.get('companyName') === " " || self.get('companyName') === "") {
      self.set('companyName', null);
    }

    if (self.get('tradingName') === null || self.get('tradingName') === undefined || self.get('tradingName') === " " || self.get('tradingName') === "") {
      self.set('tradingName', null);
    }

    if (self.get('cnpj') === null || self.get('cnpj') === undefined || self.get('cnpj') === " " || self.get('cnpj') === "") {
      self.set('cnpj', null);
    }

    if (self.get('business') === true) {
      if (self.get('companyName') === null || self.get('companyName') === undefined || self.get('companyName') === " " || self.get('companyName') === "") {
        alert('campo Razão Social é obrigatório');
        return false;
      }

      if (self.get('tradingName') === null || self.get('tradingName') === undefined || self.get('tradingName') === " " || self.get('tradingName') === "") {
        alert('campo Nome Fantasia é obrigatório');
        return false;
      }

      if (self.get('cnpj') === null || self.get('cnpj') === undefined || self.get('cnpj') === " " || self.get('cnpj') === "") {
        alert('campo CNPJ é obrigatório');
        return false;
      }
    }

    return true;
  };

  var getCustomer = function getCustomer(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/customers/paginate?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&search=' + self.get('search'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('customers', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),
    utilService: Ember.inject.service('util-service'),

    loading: true,

    searchPerPage: 10,
    searchPage: 1,
    search: null,

    //formulario
    business: false,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('permission', this.get('authenticationService').get().extras.user.permissions[0].key);
      getCustomer(this, token);
    }),
    //##########
    //permissões
    //##########
    master: function () {
      if (this.get('permission') === "MASTER") return true;else return false;
    }.property('master'),

    operatorA: function () {
      if (this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorA'),

    operatorB: function () {
      if (this.get('permission') === "OPERATOR_B" || this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorB'),

    designerA: function () {
      if (this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    designerB: function () {
      if (this.get('permission') === "DESIGNER_B" || this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    programmerA: function () {
      if (this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    programmerB: function () {
      if (this.get('permission') === "PROGRAMMER_B" || this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    customers: function () {
      return null;
    }.property('customers'),

    actions: {
      openEditCustomerModal: function openEditCustomerModal(customer) {
        $("#editCustomerModal").modal('show');
      },
      saveEditCustomer: function saveEditCustomer() {
        var token = this.get('authenticationService').get();

        var submit = validateEditCustomerForm(this);

        var self = this;

        if (submit === true) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/customers/' + self.get('showCustomer.id'),
            type: 'PUT',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: '{"email":"' + self.get('showCustomer.email') + '",' + '"fullName":"' + self.get('showCustomer.fullName') + '",' + '"cpf":"' + self.get('showCustomer.cpf') + '",' + '"phone":"' + self.get('showCustomer.phone') + '",' + '"postalCode":"' + self.get('showCustomer.postalCode') + '",' + '"number":"' + self.get('showCustomer.number') + '",' + '"complement":"' + self.get('showCustomer.complement') + '",' + '"district":"' + self.get('showCustomer.district') + '",' + '"city":"' + self.get('showCustomer.city') + '",' + '"street":"' + self.get('showCustomer.street') + '",' + '"state":"' + self.get('showCustomer.state') + '",' + '"country":"' + self.get('showCustomer.country') + '",' + '"business":"' + self.get('showCustomer.business') + '",' + '"companyName":"' + self.get('showCustomer.companyName') + '",' + '"tradingName":"' + self.get('showCustomer.tradingName') + '",' + '"cnpj":"' + self.get('showCustomer.cnpj') + '"}',
            success: function success(response) {
              self.set('loading', false);
              if (response.code !== 0 && response.code !== 1) {
                alert('Salvo com sucesso!');
                //LIMPA FORMULARIO
                clearEditCustomerForm(self);
                //BUSCA CLIENTES
                getCustomer(self, token);
                //fecha o modal
                $("#showCustomerModal").modal('hide');
                $("#editCustomerModal").modal('hide');
              } else {
                alert('Erro: ' + response.message);
              }
            },
            failure: function failure(response) {
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      saveNewCustomer: function saveNewCustomer() {
        var token = this.get('authenticationService').get();
        var submit = validateNewCustomerForm(this);
        var self = this;

        if (submit === true) {
          self.set('loading', true);

          Ember.$.ajax({
            url: _environment.default.APP.sgplServiceUrl + '/customers',
            type: 'POST',
            dataType: 'json',
            async: true,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Auth': token.token
            },
            data: '{"email":"' + self.get('email') + '",' + '"fullName":"' + self.get('fullName') + '",' + '"cpf":"' + self.get('cpf') + '",' + '"phone":"' + self.get('phone') + '",' + '"postalCode":"' + self.get('cep') + '",' + '"number":"' + self.get('number') + '",' + '"complement":"' + self.get('complement') + '",' + '"district":"' + self.get('district') + '",' + '"city":"' + self.get('city') + '",' + '"street":"' + self.get('address') + '",' + '"state":"' + self.get('state') + '",' + '"country":"' + self.get('country') + '",' + '"business":"' + self.get('business') + '",' + '"companyName":"' + self.get('companyName') + '",' + '"tradingName":"' + self.get('tradingName') + '",' + '"cnpj":"' + self.get('cnpj') + '"}',
            success: function success(response) {
              self.set('loading', false);
              if (response.code !== 0) {
                alert('Cadastrado com sucesso!');
                //LIMPA FORMULARIO
                clearNewCustomerForm(self);
                //BUSCA CLIENTES
                getCustomer(self, token);
                //fecha o modal
                $("#newCustomerModal").modal('hide');
              } else {
                alert('Erro: ' + response.message);
              }
            },
            failure: function failure(response) {
              self.set('loading', false);
              console.log(response);
            },
            error: function error(response) {
              self.set('loading', false);
              console.log(response);
            }
          });
        }
      },
      keyUpPressCnpj: function keyUpPressCnpj(e) {
        this.set('cnpj', this.get('utilService').cnpjMask(this.get('cnpj')));
      },
      keyUpPressCpf: function keyUpPressCpf(e) {
        this.set('cpf', this.get('utilService').cpfMask(this.get('cpf')));
      },
      keyUpPhone: function keyUpPhone(e) {
        this.set('phone', this.get('utilService').phoneMask(this.get('phone')));
      },
      keyUpCep: function keyUpCep(e) {
        this.set('cep', this.get('utilService').onlyNumber(this.get('cep')));
      },
      keyUpNumber: function keyUpNumber(e) {
        this.set('number', this.get('utilService').onlyNumber(this.get('number')));
      },
      keyUpPressShowCustomerCnpj: function keyUpPressShowCustomerCnpj(e) {
        this.set('showCustomer.cnpj', this.get('utilService').cnpjMask(this.get('showCustomer.cnpj')));
      },
      keyUpPressShowCustomerCpf: function keyUpPressShowCustomerCpf(e) {
        this.set('showCustomer.cpf', this.get('utilService').cpfMask(this.get('showCustomer.cpf')));
      },
      keyUpshowCustomerPhone: function keyUpshowCustomerPhone(e) {
        this.set('phone', this.get('utilService').phoneMask(this.get('showCustomer.phone')));
      },
      keyUpShowCustomerCep: function keyUpShowCustomerCep(e) {
        this.set('showCustomer.postalCode', this.get('utilService').onlyNumber(this.get('showCustomer.postalCode')));
      },
      keyUpShowCustomerNumber: function keyUpShowCustomerNumber(e) {
        this.set('showCustomer.number', this.get('utilService').onlyNumber(this.get('showCustomer.number')));
      },
      setBusiness: function setBusiness() {
        if (this.get('business') === true) this.set('business', false);else this.set('business', true);
      },
      setShowCustomerBusiness: function setShowCustomerBusiness() {
        if (this.get('showCustomer.business') === true) this.set('showCustomer.business', false);else this.set('showCustomer.business', true);
      },
      openNewCustomerModal: function openNewCustomerModal() {
        $("#newCustomerModal").modal('show');
      },
      keyUpSearchCustomer: function keyUpSearchCustomer() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getCustomer(this, token);
      },
      openShowCustomerModal: function openShowCustomerModal(customer) {
        console.log(customer);
        this.set('showCustomer', customer);
        $("#showCustomerModal").modal('show');
      },
      nextPage: function nextPage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') < this.get('customers.meta.totalPages')) {
          this.set('searchPage', this.get('searchPage') + 1);
        }
        getCustomer(this, token);
      },
      beforePage: function beforePage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') > 1) {
          this.set('searchPage', this.get('searchPage') - 1);
        }
        getCustomer(this, token);
      },
      showCustomerNfeList: function showCustomerNfeList(idCustomer) {
        var self = this;
        var token = self.get('authenticationService').get();

        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/customers/' + idCustomer + '/nfe',
          type: 'GET',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            console.log(response);
            self.set('loading', false);
            self.set('nfeList', response);
            $('#showCustomerNfeListModal').modal('show');
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            self.set('loading', false);
            console.log(response);
          }
        });
      }
    }
  });
});