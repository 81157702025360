define('ember-js-getting-started/helpers/order-product-tag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.orderProductTag = orderProductTag;
  function orderProductTag(params /*, hash*/) {
    var prod = params[0];

    if (prod === "Whatsmaker") {
      return '<span class="badge badge-success">Whatsmaker</span> ';
    }

    if (prod === "Forgram") {
      return '<span class="badge badge-danger">Forgram</span> ';
    }

    if (prod === "Connectface") {
      return '<span class="badge badge-primary">Connectface</span> ';
    }

    if (prod === "Tagclick") {
      return '<span class="badge badge-info">Tagclick</span> ';
    }

    return prod;
  }

  exports.default = Ember.Helper.helper(orderProductTag);
});