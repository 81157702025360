define('ember-js-getting-started/helpers/feedback-stars', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.feedbackStars = feedbackStars;
  function feedbackStars(params /*, hash*/) {
    var value = params[0];

    if (value === 1) {
      return '<i class="fas fa-star" style="color:#ffea00;"></i> <i class="far fa-star" style="color:#ffea00;"></i> <i class="far fa-star" style="color:#ffea00;"></i> <i class="far fa-star" style="color:#ffea00;"></i> <i class="far fa-star" style="color:#ffea00;"></i> ';
    }

    if (value === 2) {
      return '<i class="fas fa-star" style="color:#ffea00;"></i> <i class="fas fa-star" style="color:#ffea00;"></i> <i class="far fa-star" style="color:#ffea00;"></i> <i class="far fa-star" style="color:#ffea00;"></i> <i class="far fa-star" style="color:#ffea00;"></i> ';
    }

    if (value === 3) {
      return '<i class="fas fa-star" style="color:#ffea00;"></i> <i class="fas fa-star" style="color:#ffea00;"></i> <i class="fas fa-star" style="color:#ffea00;"></i> <i class="far fa-star" style="color:#ffea00;"></i> <i class="far fa-star" style="color:#ffea00;"></i> ';
    }

    if (value === 4) {
      return '<i class="fas fa-star" style="color:#ffea00;"></i> <i class="fas fa-star" style="color:#ffea00;"></i> <i class="fas fa-star" style="color:#ffea00;"></i> <i class="fas fa-star" style="color:#ffea00;"></i> <i class="far fa-star" style="color:#ffea00;"></i> ';
    }

    if (value === 5) {
      return '<i class="fas fa-star" style="color:#ffea00;"></i> <i class="fas fa-star" style="color:#ffea00;"></i> <i class="fas fa-star" style="color:#ffea00;"></i> <i class="fas fa-star" style="color:#ffea00;"></i> <i class="fas fa-star" style="color:#ffea00;"></i> ';
    }

    return 'INVALID';
  }

  exports.default = Ember.Helper.helper(feedbackStars);
});