define('ember-js-getting-started/components/product-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var getProducts = function getProducts(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/products/paginated?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&searchText=' + self.get('search'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('products', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),

    loading: true,

    searchPerPage: 10,
    searchPage: 1,
    search: null,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      getProducts(this, token);
    }),

    products: function () {
      return null;
    }.property('products'),

    actions: {
      toHeroku: function toHeroku() {
        window.open('https://dashboard.heroku.com/apps', '_blank');
      },
      keyUpSearchCustomer: function keyUpSearchCustomer() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getProducts(this, token);
      },
      openShowCustomerModal: function openShowCustomerModal(products) {
        console.log(products);
        this.set('showProduct', products);
        $("#showCustomerModal").modal('show');
      },
      nextPage: function nextPage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') < this.get('products.meta.totalPages')) {
          this.set('searchPage', this.get('searchPage') + 1);
        }
        getProducts(this, token);
      },
      beforePage: function beforePage() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') > 1) {
          this.set('searchPage', this.get('searchPage') - 1);
        }
        getProducts(this, token);
      }
    }
  });
});