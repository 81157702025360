define('ember-js-getting-started/components/ticket-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var _cleanTags = function _cleanTags(self) {
    var token = self.get('authenticationService').get();
    self.set('searchPreSale', null);
    self.set('preSale', null);
    self.set('tagSearchPreSale', null);
    self.set('searchChannel', null);
    self.set('searchStatus', null);
    self.set('search', null);
    self.set('searchProduct', null);
    self.set('searchTicketReason', null);
    self.set('showResolutionReason', null);

    self.set('searchCustomerTickets', null);
    self.set('searchUser', null);

    self.set('loading', true);
    getTickets(self, token);
  };

  var _refresh = function _refresh(self) {
    var token = self.get('authenticationService').get();
    self.set('loading', true);
    getTickets(self, token);
  };

  var clearNewTicketForm = function clearNewTicketForm(self) {
    self.set('tkPreSale', null);
    self.set('tkCustomer', null);
    self.set('tkReason', null);
    self.set('tkChannel', null);
    self.set('tkStatus', null);
    self.set('tkProduct', null);
    self.set('tkPendingReason', null);
    self.set('tkResolutionReason', null);
  };

  var getUsers = function getUsers(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/users?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&searchText=' + self.get('searchUser'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loadingSearchUser', false);
        self.set('users', response);
      },
      failure: function failure(response) {
        self.set('loadingSearchUser', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loadingSearchUser', false);
        console.log(response);
      }
    });
  };

  var getRanking = function getRanking(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/tickets/ranking',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loadingRanking', false);
        self.set('ranking', response);
      },
      failure: function failure(response) {
        self.set('loadingRanking', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loadingRanking', false);
        console.log(response);
      }
    });
  };

  var getTransferUsers = function getTransferUsers(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/users?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&searchText=' + self.get('searchTransferUser'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loadingSearchTransferUser', false);
        self.set('transferUsers', response);
      },
      failure: function failure(response) {
        self.set('loadingSearchTransferUser', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loadingSearchTransferUser', false);
        console.log(response);
      }
    });
  };

  var getTickets = function getTickets(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/tickets/paginate?' + '&page=' + self.get('searchPage') + '&perPage=' + self.get('searchPerPage') + '&search=' + self.get('search') + '&status=' + self.get('searchStatus') + '&preSale=' + self.get('searchPreSale') + '&channel=' + self.get('searchChannel') + '&idProduct=' + self.get('searchProduct.id') + '&idTicketReason=' + self.get('searchTicketReason.id') + '&searchUser=' + self.get('searchUser') + '&searchCustomer=' + self.get('searchCustomerTickets'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('tickets', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  var getTicketLog = function getTicketLog(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/tickets/' + self.get('showTicket.id') + '/log/paginate?' + '&page=' + self.get('searchPageTkLog') + '&perPage=' + self.get('searchPerPageTkLog'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loadingTicketLog', false);
        self.set('ticketLogs', response);
      },
      failure: function failure(response) {
        self.set('loadingTicketLog', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loadingTicketLog', false);
        console.log(response);
      }
    });
  };

  var getCustomer = function getCustomer(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/customers/paginate?' + '&page=1&perPage=5' + '&search=' + self.get('searchCustomer'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loading', false);
        self.set('customers', response);
      },
      failure: function failure(response) {
        self.set('loading', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loading', false);
        console.log(response);
      }
    });
  };

  var getCustomerTicket = function getCustomerTicket(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/customers/paginate?' + '&page=1&perPage=5' + '&search=' + self.get('searchCustomerTickets'),
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('loadingSearchCustomerTicket', false);
        self.set('customerTickets', response);
      },
      failure: function failure(response) {
        self.set('loadingSearchCustomerTicket', false);
        console.log(response);
      },
      error: function error(response) {
        self.set('loadingSearchCustomerTicket', false);
        console.log(response);
      }
    });
  };

  var getTicketsReason = function getTicketsReason(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/tickets/reason',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('ticketReasons', response);
      },
      failure: function failure(response) {
        console.log(response);
      },
      error: function error(response) {
        console.log(response);
      }
    });
  };

  var getProducts = function getProducts(self, token) {
    Ember.$.ajax({
      url: _environment.default.APP.sgplServiceUrl + '/products/all',
      type: 'GET',
      dataType: 'json',
      async: true,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Auth': token.token
      },
      data: null,
      success: function success(response) {
        self.set('products', response);
      },
      failure: function failure(response) {
        console.log(response);
      },
      error: function error(response) {
        console.log(response);
      }
    });
  };

  exports.default = Ember.Component.extend({
    authenticationService: Ember.inject.service('auth-token-service'),
    routing: Ember.inject.service('-routing'),
    utilService: Ember.inject.service('util-service'),

    loading: true,
    preSale: true,

    searchPerPage: 10,
    searchPage: 1,
    searchPerPageTkLog: 10,
    searchPageTkLog: 1,
    search: null,
    searchStatus: null,
    searchChannel: null,

    step0: true,
    step1: false,
    step2: false,
    step3: false,
    step4: false,

    openTransferUserSearch: false,

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('permission', this.get('authenticationService').get().extras.user.permissions[0].key);
      getTickets(this, token);
      getProducts(this, token);
      getTicketsReason(this, token);

      var handler;
      var self = this;
      handler = setInterval(function () {
        if (Ember.getOwner(self).lookup('controller:application').currentPath === 'panel.ticket') {
          console.log('clock: ' + new Date());
          _refresh(self);
        }
      }, 600000); //60000 1 minutos

      this.set('handler', handler);
    }),

    teardown: function () {
      clearInterval(this.get('handler'));
    }.on('willDestroyElement'),

    //##########
    //permissões
    //##########
    master: function () {
      if (this.get('permission') === "MASTER") return true;else return false;
    }.property('master'),

    operatorA: function () {
      if (this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorA'),

    operatorB: function () {
      if (this.get('permission') === "OPERATOR_B" || this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorB'),

    designerA: function () {
      if (this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    designerB: function () {
      if (this.get('permission') === "DESIGNER_B" || this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    programmerA: function () {
      if (this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    programmerB: function () {
      if (this.get('permission') === "PROGRAMMER_B" || this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    tickets: function () {
      return null;
    }.property('tickets'),

    permission: function () {
      return null;
    }.property('permission'),

    isGeneric: function () {
      if (this.get('permission') === "GENERIC") return true;else return false;
    }.property('isGeneric'),

    actions: {
      openTransferUserSearch: function openTransferUserSearch() {
        if (this.get('openTransferUserSearch') === true) {
          this.set('openTransferUserSearch', false);
        } else {
          this.set('openTransferUserSearch', true);
        }
      },
      refresh: function refresh() {
        _refresh(this);
      },
      editTicket: function editTicket(value) {
        this.set('editTicket', value);
        this.set('showEditSaveButton', false);
      },
      openRankingModal: function openRankingModal() {
        this.set('loadingRanking', true);
        var token = this.get('authenticationService').get();
        getRanking(this, token);
        $("#rankingModal").modal('show');
      },
      openNewTicketModal: function openNewTicketModal() {
        this.set('search', null);
        //this.set('searchStatus', null);
        this.set('searchCustomer', null);
        this.set('searchPreSale', true);
        this.set('preSale', true);

        $("#newTicketModal").modal('show');
        var token = this.get('authenticationService').get();
        getProducts(this, token);
        getTicketsReason(this, token);
        clearNewTicketForm(this);

        this.set('step0', true);
        this.set('step1', false);
        this.set('step2', false);
        this.set('step3', false);
        this.set('step4', false);
      },
      openShowTicketrModal: function openShowTicketrModal(ticket) {
        var token = this.get('authenticationService').get();

        this.set('showTicket', ticket);
        this.set('editTicket', false);

        if (this.get('showTicket.status') === 'PENDENTE') {
          this.set('showEditSaveButton', true);
        } else {
          this.set('showEditSaveButton', false);
        }

        this.set('openTransferUserSearch', false);
        this.set('searchTransferUser', null);
        this.set('transferUsers', null);

        $("#showTicketModal").modal('show');

        this.set('loadingTicketLog', true);
        getTicketLog(this, token);
      },
      nextPage: function nextPage() {
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') < this.get('tickets.meta.totalPages')) {
          this.set('searchPage', this.get('searchPage') + 1);
        }
        this.set('loading', true);
        getTickets(this, token);
      },
      beforePage: function beforePage() {
        var token = this.get('authenticationService').get();
        if (this.get('searchPage') > 1) {
          this.set('searchPage', this.get('searchPage') - 1);
        }
        this.set('loading', true);
        getTickets(this, token);
      },
      nextPageTkLog: function nextPageTkLog() {
        var token = this.get('authenticationService').get();
        if (this.get('searchPageTkLog') < this.get('ticketLogs.meta.totalPages')) {
          this.set('searchPageTkLog', this.get('searchPageTkLog') + 1);
        }
        this.set('loadingTicketLog', true);
        getTicketLog(this, token);
      },
      beforePageTkLog: function beforePageTkLog() {
        var token = this.get('authenticationService').get();
        if (this.get('searchPageTkLog') > 1) {
          this.set('searchPageTkLog', this.get('searchPageTkLog') - 1);
        }
        this.set('loadingTicketLog', true);
        getTicketLog(this, token);
      },
      setPreSale: function setPreSale(value) {
        this.set('preSale', value);
        this.set('tkPreSale', value);

        if (value === true) {
          this.set('step0', false);
          this.set('step1', false);
          this.set('step2', true);
          this.set('step3', false);
          this.set('step4', false);
        } else {
          this.set('step0', false);
          this.set('step1', true);
          this.set('step2', false);
          this.set('step3', false);
          this.set('step4', false);
        }
      },
      setCustomer: function setCustomer(value) {
        this.set('search', null);
        this.set('customers', null);
        this.set('tkCustomer', value);

        this.set('step0', false);
        this.set('step1', false);
        this.set('step2', true);
        this.set('step3', false);
        this.set('step4', false);
      },
      setReason: function setReason(value) {
        this.set('tkReason', value);

        this.set('step0', false);
        this.set('step1', false);
        this.set('step2', false);
        this.set('step3', true);
        this.set('step4', false);
      },
      setChannel: function setChannel(value) {
        this.set('tkChannel', value);

        this.set('step0', false);
        this.set('step1', false);
        this.set('step2', false);
        this.set('step3', false);
        this.set('step4', true);
      },
      setProduct: function setProduct(value) {
        this.set('tkProduct', value);

        this.set('step0', false);
        this.set('step1', true);
        this.set('step2', false);
        this.set('step3', false);
        this.set('step4', false);
      },
      backStep: function backStep() {
        if (this.get('step1') === true) {
          this.set('step0', true);
          this.set('step1', false);
          this.set('step2', false);
          this.set('step3', false);
          this.set('step4', false);
        }

        if (this.get('step2') === true) {
          this.set('step0', false);
          this.set('step1', true);
          this.set('step2', false);
          this.set('step3', false);
          this.set('step4', false);
        }

        if (this.get('step3') === true) {
          this.set('step0', false);
          this.set('step1', false);
          this.set('step2', true);
          this.set('step3', false);
          this.set('step4', false);
        }

        if (this.get('step4') === true) {
          this.set('step0', false);
          this.set('step1', false);
          this.set('step2', false);
          this.set('step3', true);
          this.set('step4', false);
        }
      },
      setStatus: function setStatus(value) {
        this.set('tkStatus', value);

        if (value === 'RESOLVIDO') {
          this.set('showPedingReason', false);
          this.set('showResolutionReason', true);
          this.set('tkResolutionReason', null);
          this.set('tkPendingReason', null);
        }

        if (value === 'PENDENTE') {
          this.set('showPedingReason', true);
          this.set('showResolutionReason', false);
          this.set('tkResolutionReason', null);
        }

        if (value === 'CANCELADO') {
          this.set('showPedingReason', false);
          this.set('showResolutionReason', true);
          this.set('tkPendingReason', null);
        }
      },
      keyUpSearchTicket: function keyUpSearchTicket() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getTickets(this, token);
      },
      keyUpSearchCustomer: function keyUpSearchCustomer() {
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getCustomer(this, token);
      },
      keyUpSearchUser: function keyUpSearchUser() {
        this.set('loadingSearchUser', true);
        var token = this.get('authenticationService').get();
        getUsers(this, token);
      },
      keyUpSearchTransferUser: function keyUpSearchTransferUser() {
        this.set('loadingSearchTransferUser', true);
        var token = this.get('authenticationService').get();
        getTransferUsers(this, token);
      },
      keyUpSearchCustomerTickets: function keyUpSearchCustomerTickets() {
        this.set('loadingSearchCustomerTicket', true);
        var token = this.get('authenticationService').get();
        getCustomerTicket(this, token);
      },
      setSearchUser: function setSearchUser(value) {
        this.set('searchUser', value);
        this.set('users', null);
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getTickets(this, token);
      },
      setSearchCustomerTickets: function setSearchCustomerTickets(value) {
        this.set('searchCustomerTickets', value);
        this.set('customerTickets', null);
        this.set('loading', true);
        var token = this.get('authenticationService').get();
        getTickets(this, token);
      },
      setLogNewComment: function setLogNewComment() {
        var token = this.get('authenticationService').get();
        var self = this;

        if (self.get('newCommentTicket') === null || self.get('newCommentTicket') === undefined || self.get('newCommentTicket') === "" || self.get('newCommentTicket') === " ") {
          alert('Faça um comentário antes de inserir uma atualização.');
          return;
        }

        self.set('loadingTicketLog', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/tickets/ticketLog',
          type: 'POST',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          data: '{"idTicket":"' + self.get('showTicket.id') + '",' + '"description":"' + self.get('newCommentTicket') + '"}',

          success: function success(response) {
            alert('Inserido!');
            self.set('loadingTicketLog', false);
            self.set('newCommentTicket', null);
            getTicketLog(self, token);
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            console.log(response);
          }
        });
      },
      saveNewTicket: function saveNewTicket() {
        var token = this.get('authenticationService').get();
        var self = this;

        self.set('searchPreSale', null);

        if (self.get('tkStatus') !== 'CANCELADO' && self.get('tkStatus') !== 'RESOLVIDO' && self.get('tkStatus') !== 'PENDENTE') {
          alert('selecione o status');
          return;
        }

        if (self.get('tkStatus') === 'CANCELADO' || self.get('tkStatus') === 'RESOLVIDO') {
          if (self.get('tkResolutionReason') === null || self.get('tkResolutionReason') === undefined || self.get('tkResolutionReason') === "" || self.get('tkResolutionReason') === " ") {
            alert('preencha o motivo');
            return;
          }
        }

        if (self.get('tkStatus') === 'PENDENTE') {
          if (self.get('tkPendingReason') === null || self.get('tkPendingReason') === undefined || self.get('tkPendingReason') === "" || self.get('tkPendingReason') === " ") {
            alert('preencha o motivo');
            return;
          }
        }

        if (self.get('tkCustomer.id') === undefined) {
          self.set('idCustomer', null);
        } else {
          self.set('idCustomer', self.get('tkCustomer.id'));
        }

        self.set('saveTicketLoading', true);
        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/tickets',
          type: 'POST',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          data: '{"preSale":"' + self.get('tkPreSale') + '",' + '"idCustomer":"' + self.get('idCustomer') + '",' + '"idTicketReason":"' + self.get('tkReason.id') + '",' + '"channel":"' + self.get('tkChannel') + '",' + '"status":"' + self.get('tkStatus') + '",' + '"idProduct":"' + self.get('tkProduct.id') + '",' + '"pendingReason":"' + self.get('tkPendingReason') + '",' + '"resolutionReason":"' + self.get('tkResolutionReason') + '"}',

          success: function success(response) {
            alert('Cadastrado com sucesso!');
            self.set('saveTicketLoading', false);
            _refresh(self);
            $("#newTicketModal").modal('hide');
          },
          failure: function failure(response) {
            alert('Erro: ' + response.responseJSON.message);
            console.log(response);
          },
          error: function error(response) {
            alert('Erro: ' + response.responseJSON.message);
            console.log(response);
          }
        });
      },
      transferTicket: function transferTicket(idTicket, user) {
        var token = this.get('authenticationService').get();
        var self = this;

        self.set('loadingSearchTransferUser', true);
        self.set('loadingTicketLog', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/tickets/transfer',
          type: 'PUT',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          data: '{"id":"' + idTicket + '",' + '"idUser":"' + user.id + '"}',
          success: function success(response) {
            self.set('loadingSearchTransferUser', false);
            self.set('openTransferUserSearch', false);

            self.set('openTransferUserSearch', false);

            self.set('showTicket.user.fullName', user.fullName);
            self.set('showTicket.user.email', user.email);

            _refresh(self);

            Ember.$.ajax({
              url: _environment.default.APP.sgplServiceUrl + '/tickets/' + self.get('showTicket.id') + '/log/paginate?' + '&page=1&perPage=10&search=' + self.get('searchTicketLog'),
              type: 'GET',
              dataType: 'json',
              async: true,
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Auth': token.token
              },
              data: null,
              success: function success(response) {
                self.set('loadingTicketLog', false);
                self.set('ticketLogs', response);
              },
              failure: function failure(response) {
                self.set('loadingTicketLog', false);
                console.log(response);
              },
              error: function error(response) {
                self.set('loadingTicketLog', false);
                console.log(response);
              }
            });

            alert('Transferido com sucesso!');
          },
          failure: function failure(response) {
            self.set('loading', false);
            alert('Erro: ' + response.responseJSON.message);
            console.log(response);
          },
          error: function error(response) {
            self.set('loading', false);
            alert('Erro: ' + response.responseJSON.message);
            console.log(response);
          }
        });
      },
      saveEditTicket: function saveEditTicket() {
        var token = this.get('authenticationService').get();
        var self = this;

        if (self.get('tkStatus') === 'CANCELADO') {
          if (self.get('tkResolutionReason') === null || self.get('tkResolutionReason') === undefined || self.get('tkResolutionReason') === "" || self.get('tkResolutionReason') === " ") {
            alert('preencha o motivo');
            return;
          }
        }

        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/tickets',
          type: 'PUT',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          data: '{"status":"' + self.get('tkStatus') + '",' + '"id":"' + self.get('showTicket.id') + '",' + '"resolutionReason":"' + self.get('tkResolutionReason') + '"}',
          success: function success(response) {
            alert('Atualizado com sucesso!');
            _refresh(self);
            $("#showTicketModal").modal('hide');
          },
          failure: function failure(response) {
            self.set('loading', false);
            alert('Erro: ' + response.responseJSON.message);
            console.log(response);
          },
          error: function error(response) {
            self.set('loading', false);
            alert('Erro: ' + response.responseJSON.message);
            console.log(response);
          }
        });
      },
      setSearchStatus: function setSearchStatus(value) {
        var token = this.get('authenticationService').get();
        this.set('searchStatus', value);
        this.set('loading', true);
        getTickets(this, token);
      },
      setSearchChannel: function setSearchChannel(value) {
        var token = this.get('authenticationService').get();
        this.set('searchChannel', value);
        this.set('loading', true);
        getTickets(this, token);
      },
      setSearchPreSale: function setSearchPreSale(value) {
        var token = this.get('authenticationService').get();
        if (value === 'Pré Venda') {
          this.set('searchPreSale', true);
          this.set('tagSearchPreSale', value);
        } else {
          this.set('searchPreSale', false);
          this.set('tagSearchPreSale', value);
        }
        this.set('loading', true);
        getTickets(this, token);
      },
      setSearchProduct: function setSearchProduct(value) {
        var token = this.get('authenticationService').get();
        this.set('searchProduct', value);
        this.set('loading', true);
        getTickets(this, token);
      },
      setSearchTicketReason: function setSearchTicketReason(value) {
        var token = this.get('authenticationService').get();
        this.set('searchTicketReason', value);
        this.set('loading', true);
        getTickets(this, token);
      },
      cleanTags: function cleanTags() {
        _cleanTags(this);
      },
      delete: function _delete(ticket) {
        var token = this.get('authenticationService').get();
        var self = this;

        if (!confirm("Tem certeza que deseja excluir este ticket?")) {
          return false;
        }

        self.set('loading', true);

        Ember.$.ajax({
          url: _environment.default.APP.sgplServiceUrl + '/tickets/' + ticket.id,
          type: 'DELETE',
          dataType: 'json',
          async: true,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Auth': token.token
          },
          success: function success(response) {
            if (response.code !== 0) {
              alert('Excluido!');
              $("#showTicketModal").modal('hide');
              _refresh(self);
            } else {
              alert('Erro: ' + response.message);
            }
          },
          failure: function failure(response) {
            self.set('loading', false);
            console.log(response);
          },
          error: function error(response) {
            self.set('loading', false);
            console.log(response);
          }
        });
      }
    }
  });
});