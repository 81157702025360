define('ember-js-getting-started/components/first-access-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    authenticationService: Ember.inject.service('auth-token-service'),

    initComponent: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('user', token.extras.user);
    }),

    user: function () {
      return null;
    }.property('user'),

    actions: {
      toGeneral: function toGeneral() {
        this.get('routing').transitionTo('panel.general');
      }
    }
  });
});