define('ember-js-getting-started/components/general-component', ['exports', 'ember-js-getting-started/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    authenticationService: Ember.inject.service('auth-token-service'),

    initComponente: Ember.on('init', function () {
      var token = this.get('authenticationService').get();
      this.set('permission', this.get('authenticationService').get().extras.user.permissions[0].key);
    }),

    permission: function () {
      return null;
    }.property('permission'),

    //##########
    //permissões
    //##########
    master: function () {
      if (this.get('permission') === "MASTER") return true;else return false;
    }.property('master'),

    operatorA: function () {
      if (this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorA'),

    operatorB: function () {
      if (this.get('permission') === "OPERATOR_B" || this.get('permission') === "OPERATOR_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('operatorB'),

    designerA: function () {
      if (this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    designerB: function () {
      if (this.get('permission') === "DESIGNER_B" || this.get('permission') === "DESIGNER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    programmerA: function () {
      if (this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerA'),

    programmerB: function () {
      if (this.get('permission') === "PROGRAMMER_B" || this.get('permission') === "PROGRAMMER_A" || this.get('permission') === "MASTER") return true;else return false;
    }.property('designerB'),

    actions: {
      toCustomer: function toCustomer() {
        this.get('routing').transitionTo('panel.customer');
      },
      toProduct: function toProduct() {
        this.get('routing').transitionTo('panel.product');
      },
      toCron: function toCron() {
        this.get('routing').transitionTo('panel.cron');
      },
      toUser: function toUser() {
        this.get('routing').transitionTo('panel.user');
      },
      toOrder: function toOrder() {
        this.get('routing').transitionTo('panel.order');
      },
      toGraph: function toGraph() {
        this.get('routing').transitionTo('panel.graph');
      },
      toTicket: function toTicket() {
        this.get('routing').transitionTo('panel.ticket');
      },
      toLogs: function toLogs() {
        this.get('routing').transitionTo('panel.logs');
      },
      toTools: function toTools() {
        this.get('routing').transitionTo('panel.tool');
      },
      toLearn: function toLearn() {
        this.get('routing').transitionTo('panel.learn');
      },
      toKnowledge: function toKnowledge() {
        this.get('routing').transitionTo('panel.knowledge');
      },
      toBillet: function toBillet() {
        this.get('routing').transitionTo('panel.billet');
      }
    }

  });
});