define('ember-js-getting-started/helpers/format-int-money', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatIntMoney = formatIntMoney;
  function formatIntMoney(params) {
    var value = params[0];

    if (value !== null && value !== undefined && value !== '') {

      var value = value.toString();
      var part1 = value.substr(0, value.length - 2);
      var part2 = value.substr(value.length - 2, value.length);
      var complete = 'R$' + part1 + ',' + part2;

      return complete;
    }
  }

  exports.default = Ember.Helper.helper(formatIntMoney);
});